import {ChangeEvent, ReactNode, useCallback, useMemo} from 'react'
import {FilterModel} from '../../models/FilterModel'
import PaginationHelper from '../extras/PaginationHelper'
import {useBooleanState} from '../hooks/useBooleanState'
import {useFilterState} from '../hooks/useFilterState'
import {useOnChange} from '../hooks/useOnChange'
import {useSafeStateUpdate} from '../hooks/useSafeStateUpdate'
import {TextInput} from '../inputs'
import {MetronicIconButton} from '../inputs/MetronicIconButton'
import {PopupFilterButton} from './ControlledFilterTable/PopupFilterButton/PopupFilterButton'
import {Table, TableProps} from './Table'
import {TableColumnOptions} from './TableColumn'
import {TableSelectionCountLabel} from './TableSelectionCountLabel'
import {TableFilterStateContext} from './useTableFilterState'

export type FilterToolbar = ReactNode

type FilterTableTableProps<T> = Pick<
  TableProps<T>,
  | 'selection'
  | 'data'
  | 'onSelectionChange'
  | 'onRowSelectionChange'
  | 'rightToolbar'
  | 'idExtractor'
  | 'actions'
  | 'hiddenColumns'
  | 'onHiddenColumnsChange'
  | 'title'
  | 'expandedRows'
  | 'expansion'
  | 'onExpandChange'
  | 'isSelectDisabled'
  | 'hideSelectAll'
>

export interface FilterTableProps<T> extends FilterTableTableProps<T> {
  onFilter: (filter: FilterModel) => void
  noPagination?: boolean
  totalItems?: number
  currentPageNumber?: number
  selectionAction?: ReactNode
  leftToolbar?: FilterToolbar
  columns: TableColumnOptions<T>[]
  className?: string
  initialFilters?: FilterModel
  advancedFilters?: ReactNode
  isSelectSingleRow?: boolean
  additionalPagesSize?: number[]
  isAllCheckBoxDisabled?: boolean
  noPadding?: boolean
  onChangePageNumber?: (pageNumber: number) => void,
  onChangePageSize?: (size: number) => void,
  currentPageSize?: number
}

export const FilterTable = <T,>({
  onFilter,
  noPagination,
  totalItems,
  currentPageNumber,
  leftToolbar,
  selectionAction,
  selection,
  data,
  onSelectionChange,
  onRowSelectionChange,
  columns,
  rightToolbar,
  className,
  initialFilters,
  advancedFilters: advancedFiltersNode,
  isSelectSingleRow,
  additionalPagesSize,
  isAllCheckBoxDisabled,
  noPadding = false,
  onChangePageNumber,
  onChangePageSize,
  currentPageSize,
  ...tableProps
}: FilterTableProps<T>) => {
  const safeUpdate = useSafeStateUpdate()
  const {
    state: isPopupOpen,
    enableState: openPopup,
    disableState: closePopup,
  } = useBooleanState(false)

  useOnChange(data, () => {
    safeUpdate(() => onSelectionChange && onSelectionChange([]))
  })

  const filterState = useFilterState(onFilter, {initialFilters})

  const {
    sortColumn,
    setSortColumn,
    pageNumber,
    pageSize,
    setPageSize,
    setPageNumber,
    setSearch,
    search,
    clearFilters,
    hasFilters,
    advancedFilters,
  } = filterState

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [setSearch]
  )

  const searchAction = useMemo(() => {
    return (
      <div className='w-100'>
        <div className='table-left-toolbar'>
          <TextInput
            className='filter-table-search-input'
            noMargin
            placeholder='Search'
            value={search}
            onChange={handleSearchChange}
          />
          {leftToolbar}
          {hasFilters && (
            <MetronicIconButton
              iconType='Code'
              iconName='Stop'
              activeColor='danger'
              size='md'
              onClick={clearFilters}
              tooltip='Clear Filters'
            />
          )}
        </div>
      </div>
    )
  }, [search, handleSearchChange, leftToolbar, hasFilters, clearFilters])

  const bodyActions = useMemo(() => {
    return (
      <div className='mt-3'>
        <TableSelectionCountLabel count={selection?.length} />
        {selectionAction && <div className='table-left-toolbar'>{selectionAction}</div>}
      </div>
    )
  }, [selection?.length, selectionAction])

  const hasAdvancedFiltersValue = useMemo(() => {
    return Boolean(search || Object.entries(advancedFilters).length)
  }, [advancedFilters, search])

  const handleChangePageNumber = useCallback(
    (pageNumber: number) => {
      setPageNumber(pageNumber);
      onSelectionChange && onSelectionChange([]);
      if (onChangePageNumber) onChangePageNumber(pageNumber);
    },
    [onSelectionChange, setPageNumber, onChangePageNumber]
  );

  const handleChangePageSize = useCallback(
    (size: number) => {
      setPageSize(size);
      if (onChangePageSize) onChangePageSize(size);
    },
    [setPageSize, onChangePageSize]
  );

  const filterButton = useMemo(() => {
    const hasAdvancedFilterInputs = Boolean(advancedFiltersNode)
    if (hasAdvancedFilterInputs) {
      return (
        <PopupFilterButton
          isOpen={isPopupOpen}
          onClose={closePopup}
          onOpen={openPopup}
          hasAdvancedFilters={hasAdvancedFiltersValue}
        >
          {advancedFiltersNode}
        </PopupFilterButton>
      )
    }
    return null
  }, [advancedFiltersNode, closePopup, hasAdvancedFiltersValue, isPopupOpen, openPopup])

  return (
    <TableFilterStateContext.Provider value={filterState}>
      <div className={className}>
        <Table
          {...tableProps}
          columns={columns}
          data={data}
          onSelectionChange={onSelectionChange}
          onRowSelectionChange={onRowSelectionChange}
          isSelectSingleRow={isSelectSingleRow}
          selection={selection}
          leftToolbar={searchAction}
          sortedColumn={sortColumn}
          onSort={setSortColumn}
          body={bodyActions}
          isAllCheckBoxDisabled={isAllCheckBoxDisabled}
          noPadding={noPadding}
          rightToolbar={
            <>
              {rightToolbar}
              {filterButton}
            </>
          }
        />
        {!noPagination && (
          <PaginationHelper
            currentPageNumber={currentPageNumber || pageNumber}
            currentPageSize={pageSize}
            onChangePageNumber={handleChangePageNumber}
            onChangePageSize={handleChangePageSize}
            total={totalItems}
            additionalPagesSize={additionalPagesSize}
          />
        )}
      </div>
    </TableFilterStateContext.Provider>
  )
}

export const INITIAL_TABLE_PAGE_SIZE = 10
