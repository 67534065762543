import {Link, Route, Switch} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {Web3RegistrationFormLogoHeader, PoweredBy, WEB3_youtube, WEB3_twitter, WEB3_facebook} from '../../../../config/logos'
import {OpenRegistration} from '../components/open-registration/OpenRegistration'

export const OpenRegistrationPage = () => {
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Helmet>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
        <img
          alt={Web3RegistrationFormLogoHeader.alt}
          src={Web3RegistrationFormLogoHeader.src}
          className='img-fluid w-300px w-100 mb-8'
        />
        <div className='w-sm-500px w-300px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/register'>
              <OpenRegistration />
            </Route>
          </Switch>
        </div>
        <div className='d-flex justify-content-between'>
        <a href={'https://www.youtube.com/@web3.0open/'} target='_blank' rel='noreferrer'>
          <img alt={WEB3_youtube.alt} src={WEB3_youtube.src} className='h-18px mt-12 ' />
        </a>
        <a href={'https://www.twitter.com/web3_open/'} target='_blank' rel='noreferrer'>
          <img alt={WEB3_twitter.alt} src={WEB3_twitter.src} className='h-18px mt-12 mx-4' />
        </a>
        <a href={'https://www.facebook.com/web3.0open/'} target='_blank' rel='noreferrer'>
          <img alt={WEB3_facebook.alt} src={WEB3_facebook.src} className='h-18px mt-12' />
        </a>
        </div>

        <a href={'https://solaax.com/'} target='_blank' rel='noreferrer'>
          <img alt={PoweredBy.alt} src={PoweredBy.src} className='h-30px mt-12' />
        </a>
      </div>
    </div>
  )
}
