import {useCallback, useEffect, useState} from 'react'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../../../../components/hooks/useLoadingState'
import {CustomFieldModel} from '../../../../../../models/system/CustomFieldModel'
import {Plural} from '../../../../../../utils/Plural'
import {SwalUtils} from '../../../../../../utils/SwalUtils'
import {
  DeleteCustomField,
  GetCustomFieldByCode,
  GetTableCustomFields,
} from '../../../redux/SystemCRUD'
import {CustomFieldInputValue} from '../../TypeFieldInput/CustomFieldTypeInput'

export interface UseCustomFieldTableHelpersOptions {
  onRefresh?: () => void
  selectedTable?: string
  isPortal?: boolean
}

export const useCustomFieldTableHelpers = ({
  onRefresh,
  selectedTable,
  isPortal = false,
}: UseCustomFieldTableHelpersOptions) => {
  const {pushError, push} = useAlerts()
  const {setIsLoading, isLoading, isKeyLoading} = useLoadingState()
  const [customFields, setCustomFields] = useState<CustomFieldModel[]>([])

  const getDbRoute = (tableCode: string) => {
    switch (tableCode) {
      case 'VENUE':
      case 'LOCATION':
        return 'acs-custom-field'

      case 'CUSTOMER':
      case 'CUSTCAT':
        return 'customer-custom-field'

      case 'FAQ':
      case 'PAGE':
      case 'POST':
        return 'cms-custom-field'

      case 'AGENDA':
      case 'EVENT':
      case 'LIBRARY':
      case 'LIBCONT':
      case 'NOTIFICATION':
      case 'PARTNER':
      case 'PARTCAT':
      case 'POLL':
      case 'PRODUCT':
      case 'PRODCAT':
      case 'SPEAKER':
      case 'WORKSHOP':
      case 'TEAMTYPE':
      case 'TEAM':
      case 'TEAMMEMBER':
      case 'GUEST':
        return 'ems-custom-field'

      case 'FNBPRODUCT':
      case 'FNBPRODCAT':
      case 'OUTLET':
        return 'fnb-custom-field'

      case 'VOUCHER':
        return 'extras-custom-field'

      default:
        return ''
    }
  }

  const deleteCustomFields = useCallback(
    (table: any, itemCodes: string[]) => {
      SwalUtils.asyncConfirm(
        async () => {
          const doneLoading = setIsLoading(itemCodes)
          try {
            const dbRoute = isPortal ? 'registration' : getDbRoute(table)
            await DeleteCustomField(dbRoute, itemCodes)
            push({
              message: `${Plural.pluralize(
                itemCodes.length,
                'Custom Field',
                'Custom Fields'
              )} has been deleted`,
              timeout: 5000,
              variant: 'success',
            })
          } catch (e: any) {
            pushError(e)
          } finally {
            doneLoading()
            onRefresh?.()
          }
        },
        {
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          confirmButtonText: 'Yes, delete it!',
        }
      )
    },
    [isPortal, onRefresh, push, pushError, setIsLoading]
  )

  const getCustomFieldByCode = useCallback(
    async (item: CustomFieldModel) => {
      const doneLoading = setIsLoading(item.code)
      try {
        // const db = getDbRoute(item.table.code)
        const db = isPortal ? 'registration' : getDbRoute(item.table.code)
        const {data} = await GetCustomFieldByCode(db, item.code)
        return data
      } finally {
        doneLoading()
      }
    },
    [isPortal, setIsLoading]
  )

  const deleteCustomFieldSingle = useCallback(
    (item: CustomFieldModel) => {
      deleteCustomFields(item.table.code, [item.code])
    },
    [deleteCustomFields]
  )

  const deleteCustomFieldBulk = useCallback(
    (items: CustomFieldModel[]) => {
      deleteCustomFields(
        selectedTable,
        items.map((item) => item.code)
      )
    },
    [deleteCustomFields, selectedTable]
  )

  const isCustomFieldLoading = useCallback(
    (item: CustomFieldModel) => isKeyLoading(item.code),
    [isKeyLoading]
  )

  const getCustomFieldsByTable = useCallback(
    async (table: string) => {
      // const db = getDbRoute(table)
      const db = isPortal ? 'registration' : getDbRoute(table)
      
      if (db) {
        try {
          const {data} = await GetTableCustomFields(db, table)
          setCustomFields(data)
        } catch (e: any) {
          pushError(e)
        }
      }
    },
    [isPortal, pushError]
  )

  const handleTranslationChange = useCallback(
    (formik, lang: string, values: CustomFieldInputValue[]) => {
      const newValues = customFields.map((customField) => {
        const value = values.find((val) => val.customFieldCode === customField.code)?.value || null

        return {
          customFieldCode: customField.code,
          value: value,
          isRequired: customField.isRequired,
          isActive: customField.isActive,
          type: customField.type.code,
        }
      })

      formik.setFieldValue(`translations.${lang}.customFieldValues`, newValues)
    },
    [customFields]
  )

  useEffect(() => {
    if (selectedTable) {
      getCustomFieldsByTable(selectedTable)
    }
  }, [getCustomFieldsByTable, selectedTable])

  return {
    deleteCustomFieldSingle,
    deleteCustomFieldBulk,
    getCustomFieldByCode,
    isCustomFieldLoading,
    getCustomFieldsByTable,
    customFields,
    getDbRoute,
    isLoading,
    handleTranslationChange,
  }
}
