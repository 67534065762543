import {FormikContextType} from 'formik'
import {TextInput} from '../../../../../components/inputs'
import {useFormikTextInputHelpers} from '../../../../../components/inputs/hooks/useFormikTextInputHelper'
import { MobileNumberInput } from '../../../../../components/inputs/MobileNumberInput'
import { AvatarImageInput } from '../../../../../components/inputs/AvatarImageInput/AvatarImageInput'
import { Button } from '../../../../../components/inputs/Button'
import { useCallback, useRef } from 'react'
import { useFormikStringInputHelpers } from '../../../../../components/inputs/hooks/useFormikStringInputHelpers'
import { useFormikImageInputHelpers } from '../../../../../components/inputs/hooks/useFormikImageInputHelper'
import { ImageInputValue } from '../../../../../components/inputs/FileInput/ImageInputValue'

export interface RegistrationCustomerInformationValues {
  // title: string
  name: string
  // lastName: string
  email: string
  designation: string
  customerOrganization: string
  mobileNumber: string
  passportPhoto: ImageInputValue | null
  passportPhotoCropped: ImageInputValue | null
}

export interface RegistrationCustomerInformationProps<
  T extends RegistrationCustomerInformationValues
> {
  formik: FormikContextType<T>
}

export const RegistrationCustomerInformation = <T extends RegistrationCustomerInformationValues>({
  formik,
}: RegistrationCustomerInformationProps<T>) => {
  const textInputHelpers = useFormikTextInputHelpers(formik)
  const avatarInputRef = useRef<HTMLInputElement>(null)
  const stringInputHelpers = useFormikStringInputHelpers(formik)
  const imageInputHelpers = useFormikImageInputHelpers(formik)

  const handleUploadPhotoClick = useCallback(() => {
    avatarInputRef.current?.click()
  }, [])

  const handleAvatarInputChange = useCallback(
    (image: ImageInputValue | null) => {
      formik.setFieldValue('passportPhoto', image)
    },
    [formik]
  )

  const handleResetPhotoClick = useCallback(() => {
    formik.setFieldValue('passportPhoto', null)
    formik.setFieldValue('passportPhotoCropped', null)
  }, [formik])
  return (
    <div className='row'>
      {/* <div className='col-12'>
        <TextInput label='Title' readOnly {...textInputHelpers.getProps('title')} />
      </div> */}
      <div className='col-12'>
        <TextInput label='Name' readOnly {...textInputHelpers.getProps('name')} />
      </div>
      {/* <div className='col-12'>
        <TextInput label='Last Name' readOnly {...textInputHelpers.getProps('lastName')} />
      </div> */}
      <div className='col-12'>
        <TextInput label='Email' readOnly {...textInputHelpers.getProps('email')} />
      </div>
   
      <div className='col-12'>
        <MobileNumberInput
          label='Mobile number'
          helperMessage='Ex: +971551234567'
          {...stringInputHelpers.getProps('mobileNumber')}
        />
      </div>
      <div className='col-12'>
        <AvatarImageInput
          inputRef={avatarInputRef}
          height={500}
          width={500}
          className='mb-3'
          label='Upload personal photo'
          {...imageInputHelpers.getProps('passportPhoto')}
          onChange={handleAvatarInputChange}
          showTakePhoto={false}
        />
        <div className='d-flex justify-content-end mb-5'>
          <Button type='button' className='me-1' variant='danger' onClick={handleResetPhotoClick}>
            Clear
          </Button>
          <Button type='button' variant='primary' onClick={handleUploadPhotoClick}>
            Upload
          </Button>
        </div>
      </div>
      <div className='col-12 col-md-6'>
        <TextInput label='Designation'  {...textInputHelpers.getProps('designation')} />
      </div>
      <div className='col-12 col-md-6'>
        <TextInput
          label='Organization'
          {...textInputHelpers.getProps('customerOrganization')}
        />
      </div>
    </div>
  )
}

export const STEP_CUSTOMER_KEYS: Array<keyof RegistrationCustomerInformationValues> = [
  'designation',
  'email',
  'name',
  // 'lastName',
  'customerOrganization',
  // 'title',
  'mobileNumber',
  'passportPhoto',

]
