import {ChangeEvent, useCallback, useMemo} from 'react'
import {
  CheckboxInput,
  DatePickerInput,
  SelectInput,
  TextInput,
  TextAreaInput,
} from '../../../../../components/inputs'
import {NumberInput} from '../../../../../components/inputs/NumberInput/NumberInput'
import {TreeSelect} from '../../../../../components/inputs/TreeSelect/TreeSelect'
import {
  CustomFieldModel,
  CustomFieldCreateValue,
} from '../../../../../models/system/CustomFieldModel'
import {DateUtil} from '../../../../../utils/DateUtil'

export interface CustomFieldInputValue extends CustomFieldCreateValue {
  isRequired: boolean
  isActive: boolean
  type: string
}

export interface CustomFieldTypeInputProps {
  fields: CustomFieldModel[]
  onChange: (value: CustomFieldInputValue[]) => void
  value: CustomFieldInputValue[]
  containerType?: string
}

export const CustomFieldTypeInput = ({
  fields,
  onChange,
  value,
  containerType,
}: CustomFieldTypeInputProps) => {
  const getTextFieldValue = useCallback((fieldValue?: CustomFieldInputValue): string => {
    if (fieldValue && typeof fieldValue.value === 'string') {
      return fieldValue.value
    }
    return ''
  }, [])
  const getNumberFieldValue = useCallback((fieldValue?: CustomFieldInputValue): number => {
    if (fieldValue && typeof fieldValue.value === 'number') {
      return fieldValue.value
    }
    return 0
  }, [])
  const getDateFieldValue = useCallback((fieldValue?: CustomFieldInputValue): Date | null => {
    if (fieldValue && typeof fieldValue.value === 'string') {
      const mapDate = DateUtil.getDateFromApiString(fieldValue.value)
      return mapDate
    }
    return null
  }, [])

  const getDropdownFieldValue = useCallback((fieldValue?: CustomFieldInputValue): string => {
    if (fieldValue && Array.isArray(fieldValue.value)) {
      const value = fieldValue.value[0]
      return value
    }
    return ''
  }, [])
  const getMultipleSelectFieldValue = useCallback(
    (fieldValue?: CustomFieldInputValue): string[] => {
      if (fieldValue && Array.isArray(fieldValue.value)) {
        return fieldValue.value
      }
      return []
    },
    []
  )

  const getBooleanFieldValue = useCallback((fieldValue?: CustomFieldInputValue): boolean => {
    if (fieldValue) {
      return Boolean(fieldValue.value)
    }
    return false
  }, [])

  const handleInputChange = useCallback(
    (field: CustomFieldModel, inputValue: string | number | string[] | boolean | Date | null) => {
      const newValues = [...value]
      const fieldValueIndex = newValues.findIndex((item) => item.customFieldCode === field.code)

      if (fieldValueIndex >= 0) {
        newValues[fieldValueIndex] = {...newValues[fieldValueIndex], value: inputValue}
      } else {
        newValues.push({
          customFieldCode: field.code,
          value: inputValue,
          isRequired: field.isRequired,
          isActive: field.isActive,
          type: field.type.code,
        })
      }

      onChange(newValues)
    },
    [onChange, value]
  )

  const getFieldErrorMessage = useCallback(
    (field: CustomFieldModel, fieldValue?: CustomFieldInputValue): string | undefined => {
      if (field.isRequired && !fieldValue?.value) {
        return 'Field is required'
      }
    },
    []
  )

  const getCheckboxChangeHandler = useCallback(
    (field: CustomFieldModel) => (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.checked
      handleInputChange(field, inputValue)
    },
    [handleInputChange]
  )

  const getDateChangeHandler = useCallback(
    (field: CustomFieldModel) => (value: Date | null) => {
      if (value) {
        const mapDate = DateUtil.convertDateToApiString(value)
        handleInputChange(field, mapDate)
      }
    },
    [handleInputChange]
  )

  const getTextFieldChangeHandler = useCallback(
    (field: CustomFieldModel) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const inputValue = e.target.value
      handleInputChange(field, inputValue ? inputValue : null)
    },
    [handleInputChange]
  )
  const getTextFieldAreaChangeHandler = useCallback(
    (field: CustomFieldModel) => (e: ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = e.target.value
      handleInputChange(field, inputValue ? inputValue : null)
    },
    [handleInputChange]
  )
  const getNumberFieldChangeHandler = useCallback(
    (field: CustomFieldModel) => (value: number) => {
      handleInputChange(field, value ? value : 0)
    },
    [handleInputChange]
  )
  const getTreeChangeHandler = useCallback(
    (field: CustomFieldModel) => (values: string[]) => {
      handleInputChange(field, values)
    },
    [handleInputChange]
  )
  const getDropdownChangeHandler = useCallback(
    (field: CustomFieldModel) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const arrayValue = []
      arrayValue.push(e.target.value)
      handleInputChange(field, arrayValue)
    },
    [handleInputChange]
  )

  const booleanFields = useMemo(() => {
    const booleanNodes = fields
      .filter((field) => field.type.code === 'BOOLEAN' && field.isActive)
      .map((field) => {
        const fieldValue = value.find((item) => item.customFieldCode === field.code)
        return (
          <CheckboxInput
            key={field.code}
            label={field.name}
            checked={getBooleanFieldValue(fieldValue)}
            onChange={getCheckboxChangeHandler(field)}
          />
        )
      })

    if (booleanNodes.length > 0) {
      const rows = []
      let currentRow: JSX.Element[] = []
      booleanNodes.forEach((node) => {
        if (containerType === 'page' && currentRow.length === 3) {
          rows.push(currentRow)
          currentRow = []
        }
        if (containerType !== 'page' && currentRow.length === 2) {
          rows.push(currentRow)
          currentRow = []
        }

        currentRow.push(node)
      })
      if (currentRow.length > 0) {
        rows.push(currentRow)
      }

      return containerType === 'page'
        ? rows.map((row, index) => (
            <div className='row' key={index}>
              <div className='col-12 d-flex justify-content-end'>
                {row.map((node) => (
                  <div key={node.key}>{node}</div>
                ))}
              </div>
            </div>
          ))
        : rows.map((row, index) => (
            <div className='row' key={index}>
              <div className='col-12 d-flex justify-content-start'>
                {row.map((node) => (
                  <div className='col-6' key={node.key}>
                    {node}
                  </div>
                ))}
              </div>
            </div>
          ))
    }

    return null
  }, [containerType, fields, getBooleanFieldValue, getCheckboxChangeHandler, value])

  const fieldNodes = useMemo(() => {
    return fields.map((field) => {
      const fieldValue = value.find((item) => item.customFieldCode === field.code)

      if (field.type.code === 'STRING') {
        const label = field.name.length > 20 ? 'Enter here' : `Enter ${field.name}`;
        if (field.isActive) {
          return (
            <TextInput
              key={field.code}
              className={containerType === 'page' ? 'col-12 col-md-6' : 'col-12'}
              placeholder={label}
              errorMessage={getFieldErrorMessage(field, fieldValue)}
              onChange={getTextFieldChangeHandler(field)}
              label={field.name}
              value={getTextFieldValue(fieldValue)}
              isTouched
            />
          )
        }
      }
      if (field.type.code === 'TEXT') {
        const label = field.name.length > 20 ? 'Enter here' : `Enter ${field.name}`
        if (field.isActive) {
          return (
            <TextAreaInput
              key={field.code}
              className={containerType === 'page' ? 'col-12 col-md-6' : 'col-12'}
              placeholder={label}
              errorMessage={getFieldErrorMessage(field, fieldValue)}
              label={field.name}
              isTouched
              onChange={getTextFieldAreaChangeHandler(field)}
              value={getTextFieldValue(fieldValue)}
            />
          )
        }
      }

      if (field.type.code === 'INT') {
        if (field.isActive) {
          return (
            <NumberInput
              key={field.code}
              label={field.name}
              className={containerType === 'page' ? 'col-12 col-md-6' : 'col-12'}
              errorMessage={getFieldErrorMessage(field, fieldValue)}
              min={0}
              value={getNumberFieldValue(fieldValue)}
              onChange={getNumberFieldChangeHandler(field)}
            />
          )
        }
      }
      if (field.type.code === 'DATE') {
        if (field.isActive) {
          return (
            <DatePickerInput
              key={field.code}
              label={field.name}
              className={containerType === 'page' ? 'col-12 col-md-6' : 'col-12'}
              onChange={getDateChangeHandler(field)}
              value={getDateFieldValue(fieldValue)}
            />
          )
        }
      }
      if (field.type.code === 'OPTIONS' && field.options && !field.isMultipleOptions) {
        if (field.isActive) {
          return (
            <SelectInput
              key={field.code}
              errorMessage={getFieldErrorMessage(field, fieldValue)}
              label={field.name}
              className={containerType === 'page' ? 'col-12 col-md-6' : 'col-12'}
              placeholder='Select here'
              value={getDropdownFieldValue(fieldValue)}
              onChange={getDropdownChangeHandler(field)}
              items={[...field.options.map((item) => ({label: item.name, value: item.code}))]}
              allowInvalidValue
            />
          )
        }
      }
      if (field.type.code === 'OPTIONS' && field.options && field.isMultipleOptions) {
        if (field.isActive) {
          return (
            <TreeSelect
              key={field.code}
              className={containerType === 'page' ? 'col-12 col-md-6' : 'col-12'}
              label={field.name}
              items={[...field.options.map((item) => ({label: item.name, value: item.code}))]}
              onChange={getTreeChangeHandler(field)}
              values={getMultipleSelectFieldValue(fieldValue)}
            />
          )
        }
      }
      return null
    })
  }, [
    containerType,
    fields,
    getDateChangeHandler,
    getDateFieldValue,
    getDropdownChangeHandler,
    getDropdownFieldValue,
    getFieldErrorMessage,
    getMultipleSelectFieldValue,
    getNumberFieldChangeHandler,
    getNumberFieldValue,
    getTextFieldAreaChangeHandler,
    getTextFieldChangeHandler,
    getTextFieldValue,
    getTreeChangeHandler,
    value,
  ])

  return containerType === 'page' ? (
    <>
      {fieldNodes}
      {booleanFields}
    </>
  ) : (
    <>
      <div className='row mt-5'>
        {booleanFields}
        {fieldNodes}
      </div>
    </>
  )
}
