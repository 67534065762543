import {FormikContextType} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {concat} from 'lodash'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {Button} from '../../inputs/Button'
import {ListCountInputItemValue} from '../../inputs/VoucherInput/ListCountInputItem'
import {
  ProductVoucherTable,
  ProductVoucherModel,
  columns,
} from '../BookingWizardTables/ProductVoucherTable'
import {ProductModel} from '../../../models/ems/ProductModel'
import {useOnChange} from '../../hooks/useOnChange'
import {useLocalTableSearch} from '../../hooks/useLocalTableSearch'
import {map, uniq} from 'lodash'
import {
  BookingFormValues,
  BookingWizardProductVenueStepFormValues,
  ProductVoucherModalType,
} from '../../../models/booking-wizard/BookingWizard'
import {AddProductVoucherModal} from '../component/AddProductVoucherModal'
import {EditProductVoucherModal} from '../component/EditProductVoucherModal'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'

export interface BookingWizardProductVoucherSharedStepProps<
  T extends BookingWizardProductVenueStepFormValues
> {
  formik: FormikContextType<T>
  bookingForm?: BookingFormValues
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  productSearchResults?: GlobalSearchModel<ProductModel>
  searchVouchers: (filter?: FilterModel) => Promise<void>
  refreshProductsList: (filter?: FilterModel) => Promise<void>
}

export const BookingWizardProductVoucherSharedStep = <
  T extends BookingWizardProductVenueStepFormValues
>({
  formik,
  bookingForm,
  productSearchResults,
  refreshProductsList,
  searchVouchers,
  voucherSearchResults,
}: BookingWizardProductVoucherSharedStepProps<T>) => {
  const [modalType, setModalType] = useState<ProductVoucherModalType>()
  const [currentSelectedProductVoucher, setCurrentSelectedProductVoucher] =
    useState<ProductVoucherModel>()
  const [productsSelectedValues, setProductsSelectedValues] = useState<
    ListCountInputItemValue<ProductModel>[]
  >([])
  const [vouchersSelectedValues, setVouchersSelectedValues] = useState<
    ListCountInputItemValue<VoucherModel>[]
  >([])

  const isAllProductsSelected = useMemo(() => {
    return productSearchResults
      ? productSearchResults.data.every((item) => {
          const isSelected = formik.values.products.some((value) => value.data?.code === item.code)
          if (isSelected) {
            return true
          }
          return false
        })
      : false
  }, [formik.values.products, productSearchResults])

  const isAllVouchersSelected = useMemo(() => {
    return voucherSearchResults?.data.every((item) => {
      const isSelected = formik.values.vouchers.some((value) => value.data?.code === item.code)
      if (isSelected) {
        return true
      }
      return false
    })
  }, [formik.values.vouchers, voucherSearchResults?.data])

  const tableData = useMemo(() => {
    return formik.values.products || formik.values.vouchers
      ? concat(formik.values.products, formik.values.vouchers as any)
          .filter((item) => item !== null)
          .map((item) => {
            return {
              code: item.data.code,
              qty: item.count,
              name: item.data.name,
              type: item.type,
              isSeated: item.data?.isSeated ? item.data.isSeated : false,
            }
          })
      : null
  }, [formik.values.products, formik.values.vouchers])

  const {searchableLocalTableData, filterSearchableLocalTableData} = useLocalTableSearch({
    data: tableData,
    columns: columns ? (map(columns, 'field') as Array<keyof ProductVoucherModel>) : null,
  })

  useEffect(() => {
    searchVouchers()
    refreshProductsList()
  }, [refreshProductsList, searchVouchers])

  const handleCloseModal = useCallback(() => {
    if (modalType === 'product') refreshProductsList()
    if (modalType === 'voucher') searchVouchers()
    setModalType(undefined)
    setCurrentSelectedProductVoucher(undefined)
    if (productsSelectedValues && productsSelectedValues.length) {
      setProductsSelectedValues((products) =>
        products.filter((item) => item.data !== null && !item.isNew)
      )
    }
    if (vouchersSelectedValues && vouchersSelectedValues.length) {
      setVouchersSelectedValues((voucher) =>
        voucher.filter((item) => item.data !== null && !item.isNew)
      )
    }
  }, [
    modalType,
    productsSelectedValues,
    refreshProductsList,
    searchVouchers,
    vouchersSelectedValues,
  ])

  const handleAddProductOrVoucher = useCallback(() => {
    setTimeout(() => {
      let productsWithoutIsNew = [...productsSelectedValues]
      productsWithoutIsNew = productsWithoutIsNew
        .filter((item) => item.data && item.data !== null)
        .map((item) => {
          return {
            id: item.id,
            data: item.data,
            count: item.count,
            type: 'product',
          }
        })
      let vouchersWithoutIsNew = [...vouchersSelectedValues]

      vouchersWithoutIsNew = vouchersWithoutIsNew
        .filter((item) => item.data && item.data !== null)
        .map((item) => {
          return {id: item.id, data: item.data, count: item.count, type: 'voucher'}
        })

      if (productsWithoutIsNew) formik.setFieldValue('products', productsWithoutIsNew)
      if (vouchersWithoutIsNew) formik.setFieldValue('vouchers', vouchersWithoutIsNew)
      handleCloseModal()
    }, 200)
  }, [formik, handleCloseModal, productsSelectedValues, vouchersSelectedValues])

  const handleOnChangeProduct = useCallback(
    (newValues: ListCountInputItemValue<ProductModel>[]) => {
      setProductsSelectedValues(newValues)
    },
    []
  )

  const handleVoucherChange = useCallback((newValues: ListCountInputItemValue<VoucherModel>[]) => {
    setVouchersSelectedValues(newValues)
  }, [])

  const handleOnEditProductVoucher = useCallback(
    (type: ProductVoucherModalType, value: ProductVoucherModel) => {
      let newProductsValues = [...formik.values.products]
      let newVouchersValues = [...formik.values.vouchers]
      let index = -1
      if (type === 'product') {
        const found = newProductsValues.find((item, idx) => {
          if (item.data?.code === value.code) {
            index = idx
            return item
          }
          return null
        })
        if (found) {
          newProductsValues[index] = {
            ...found,
            count: value.qty,
          }
          formik.setFieldValue('products', newProductsValues)
          handleCloseModal()
        }
      }

      if (type === 'voucher') {
        const found = newVouchersValues.find((item, idx) => {
          if (item.data?.code === value.code) {
            index = idx
            return item
          }
          return null
        })

        if (found) {
          newVouchersValues[index] = {
            ...found,
            count: value.qty,
          }

          formik.setFieldValue('vouchers', newVouchersValues)
          handleCloseModal()
        }
      }
    },
    [formik, handleCloseModal]
  )

  const handleDeleteProductsVouchers = useCallback(
    (codes: string[]) => {
      let formikProductValues = [...formik.values.products]
      let formikVoucherValues = [...formik.values.vouchers]
      for (let i = 0; i < codes.length; i++) {
        const foundProduct = formikProductValues.find((item) => item?.data?.code === codes[i])
        const foundvoucher = formikVoucherValues.find((item) => item?.data?.code === codes[i])

        if (foundProduct) {
          formikProductValues = formikProductValues.filter(
            (item) => item.data?.code !== foundProduct.data?.code
          )
        } else if (foundvoucher) {
          formikVoucherValues = formikVoucherValues.filter(
            (item) => item.data?.code !== foundvoucher.data?.code
          )
        }
      }
      formik.setFieldValue('products', formikProductValues)
      setProductsSelectedValues(formikProductValues)
      formik.setFieldValue('vouchers', formikVoucherValues)
      setVouchersSelectedValues(formikVoucherValues)
    },
    [formik]
  )

  const handleEditProductsVouchers = useCallback(
    (data: ProductVoucherModel) => {
      if (productSearchResults && productSearchResults.data?.length) {
        const p = productSearchResults.data.find((item) => item.code === data.code)
        if (p) setModalType('product')
      }
      if (voucherSearchResults && voucherSearchResults.data?.length) {
        const v = voucherSearchResults.data.find((item) => item.code === data.code)
        if (v) setModalType('voucher')
      }
      setCurrentSelectedProductVoucher(data)
    },
    [productSearchResults, voucherSearchResults]
  )

  const productsValues = useMemo(() => {
    if (productsSelectedValues.length !== formik.values.products.length) {
      const newData = productsSelectedValues.concat(formik.values.products)
      return uniq(newData)
    } else return formik.values.products
  }, [formik.values.products, productsSelectedValues])

  const vouchersValues = useMemo(() => {
    if (vouchersSelectedValues.length !== formik.values.vouchers.length)
      return vouchersSelectedValues
    else return formik.values.vouchers
  }, [formik.values.vouchers, vouchersSelectedValues])

  useOnChange(bookingForm, () => {
    if (bookingForm) {
      setTimeout(() => {
        if (bookingForm.products) {
          setProductsSelectedValues(bookingForm.products)
          formik.setFieldValue('products', bookingForm.products)
        }
        if (bookingForm.vouchers) {
          setVouchersSelectedValues(bookingForm.vouchers)
          formik.setFieldValue('vouchers', bookingForm.vouchers)
        }
      }, 200)
    }
  })

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h5 className='my-10 px-11'>Add product/voucher</h5>
        </div>
        <div className='d-flex gap-3 px-11 mb-3'>
          <Button
            className='btn btn-primary px-10'
            onClick={() => {
              setModalType('product')
            }}
            disabled={isAllProductsSelected}
          >
            {isAllProductsSelected ? 'No Products available' : 'Add products'}
          </Button>
          <Button
            className='btn btn-primary px-10'
            onClick={() => {
              setModalType('voucher')
            }}
            disabled={isAllVouchersSelected}
          >
            {isAllVouchersSelected ? 'No Vouchers available' : 'Add Voucher'}
          </Button>
        </div>
        <div>
          <ProductVoucherTable
            onDelete={handleDeleteProductsVouchers}
            onEdit={handleEditProductsVouchers}
            data={searchableLocalTableData}
            onFilter={filterSearchableLocalTableData}
          />
        </div>
      </div>
      {currentSelectedProductVoucher ? (
        <EditProductVoucherModal
          modalType={modalType}
          onModalClose={handleCloseModal}
          data={currentSelectedProductVoucher}
          onEdit={handleOnEditProductVoucher}
        />
      ) : (
        <AddProductVoucherModal
          modalType={modalType}
          onModalClose={handleCloseModal}
          productSearchResults={productSearchResults}
          refreshProductsList={refreshProductsList}
          productsValues={productsValues}
          onProductChange={handleOnChangeProduct}
          voucherSearchResults={voucherSearchResults}
          vouchersValues={vouchersValues}
          onVoucherChange={handleVoucherChange}
          searchVouchers={searchVouchers}
          onAdd={handleAddProductOrVoucher}
        />
      )}
    </div>
  )
}
