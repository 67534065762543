/* eslint-disable no-lone-blocks */
import {useCallback, useEffect, useMemo, useState} from 'react'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {ButtonCrumbAttributes} from '../../../../components/ButtonCrumbs/ButtonCrumb'
import {ButtonCrumbs} from '../../../../components/ButtonCrumbs/ButtonCrumbs'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {useQueryParam} from '../../../../components/hooks/useQueryParam'
import {Button, ButtonVariant} from '../../../../components/inputs/Button'
// import {BaseFileInputValue} from '../../../../components/inputs/FileInput/BaseFileInputValue'
// import {FileInputValueCollection} from '../../../../components/inputs/FileInput/FileInputValueCollection'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {
  RegistrationCustomerInformation,
  RegistrationCustomerInformationValues,
  STEP_CUSTOMER_KEYS,
} from './steps/RegistrationCustomerInformation'
// import {
//   RegistrationPersonalAssistant,
//   RegistrationPersonalAssistantValues,
//   STEP_ASSISTANT_KEYS,
// } from './steps/RegistrationPersonalAssistant'
// import {
//   RegistrationPersonalInformation,
//   RegistrationPersonalInformationValues,
//   STEP_INFORMATION_KEYS,
// } from './steps/RegistrationPersonalInformation'
import {pick} from 'lodash'
import {MobileNumberParser} from '../../../../utils/MobileNumberParser'
import {useHistory} from 'react-router-dom'
import {useDebounce} from '../../../../components/hooks/useDebounce'
// import {DateUtil} from '../../../../utils/DateUtil'
import {CustomerModel} from '../../../../models/CustomerModel'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {UpdateRegistration, VerifyRegistrationHash} from '../redux/CustomerRegistrationCRUD'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'
import {
  RegistrationOtherInformation,
  RegistrationOtherInformationValues,
  STEP_OTHER_KEYS,
} from './steps/RegistrationOtherInformation'
import {useCustomFieldTableHelpers} from '../../../default/system/components/tables/CustomFieldTable/useCustomFieldTableHelpers'
import {CustomFieldInputValue} from '../../../default/system/components/TypeFieldInput/CustomFieldTypeInput'
import {CustomFieldCreateValue, CustomFieldModel} from '../../../../models/system/CustomFieldModel'
import {getFieldInitialValue} from '../../../default/system/hooks/useCustomFieldFormFields'
// import { CustomFieldCreateValue } from '../../../../models/system/CustomFieldModel'

enum FormStep {
  CUSTOMER = 'CUSTOMER',
  // INFORMATION = 'INFORMATION',
  // ASSISTANT = 'ASSISTANT',
  OTHER = 'OTHER',
}

const StepOrder = [FormStep.CUSTOMER, FormStep.OTHER]

export interface RegistrationFormValues
  extends RegistrationCustomerInformationValues,
    // RegistrationPersonalInformationValues,
    RegistrationOtherInformationValues {}

export const Registration = () => {
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState(FormStep.CUSTOMER)
  const {value: hash} = useQueryParam('hash')
  const {pushError} = useAlerts()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const {staticUrls, organizationCode} = useAppConfig()
  const [_data, setData] = useState<Partial<CustomerModel>>()

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      if (hash) {
        try {
          const payload = getPayload(hash, values)
          await UpdateRegistration(payload)
          setHasSubmitted(true)
        } catch (e: any) {
          pushError(e)
        }
      }
    },
    validationSchema,
    validateOnMount: true,
  })
  const {customFields} = useCustomFieldTableHelpers({
    selectedTable: 'CUSTOMER',
    isPortal: true,
  })

  const resetFormValueFromHash = useCallback(async () => {
    try {
      if (hash) {
        const {data} = await VerifyRegistrationHash(hash)
        setData(data)
        const formData = getFormData(
          organizationCode ? staticUrls.public : `${staticUrls.public}/${data.organization?.code}`,
          data,
          customFields,
          data.customFieldValues
        )

        formik.setValues(formData)
      } else {
        throw new Error('Invalid hash')
      }
    } catch (e: any) {
      pushError(e)
      history.replace('/error/403')
    }
  }, [customFields, formik, hash, history, organizationCode, pushError, staticUrls.public])


  useEffect(() => {
    if (customFields && _data) {
      const customFieldsWithValues = mapCustomFieldValuesToCustomFields(customFields, _data.customFieldValues || [])

      const initialValuesWithCustomFields = {
        ...INITIAL_VALUES,
        name: _data.name || INITIAL_VALUES.name,
        email: _data.email || INITIAL_VALUES.email,
        designation: _data.designation || INITIAL_VALUES.designation,
        customerOrganization: _data.customerOrganization || INITIAL_VALUES.customerOrganization,
        mobileNumber: _data.mobile || INITIAL_VALUES.mobileNumber,
        passportPhoto: _data.photo ? new ImageInputValue(staticUrls.public, _data.photo) : INITIAL_VALUES.passportPhoto,
        passportPhotoCropped: INITIAL_VALUES.passportPhotoCropped,
        customFields: customFieldsWithValues,
      }
  
      formik.resetForm({ values: initialValuesWithCustomFields })
    }
    //dont add dependencies
  }, [customFields, _data])


  const mapCustomFieldValuesToCustomFields = (
    customFields: CustomFieldModel[],
    customFieldValues: CustomFieldCreateValue[]
  ): CustomFieldInputValue[] => {
    return customFields.map((field) => {
      const fieldValue = customFieldValues.find(value => value.customFieldCode === field.code)
      return {
        customFieldCode: field.code, 
        value: fieldValue ? fieldValue.value : getFieldInitialValue(field.type.code, field.isMultipleOptions),
        type: field.type.code, 
        isRequired: field.isRequired,
        isActive: field.isActive,
      }
    })
  }
  

  const getStepVariant = useCallback(
    (step: FormStep): ButtonVariant => {
      const isPrevious = StepOrder.indexOf(step) < StepOrder.indexOf(currentStep)
      if (isPrevious) {
        return 'success'
      }
      if (currentStep === step) {
        return 'primary'
      }
      return 'default'
    },
    [currentStep]
  )

  const crumbs = useMemo((): ButtonCrumbAttributes[] => {
    return [
      {
        value: FormStep.CUSTOMER,
        label: <MetronicIcon iconType='General' iconName='User' />,
        className: 'btn-icon',
        variant: getStepVariant(FormStep.CUSTOMER),
      },
      // {
      //   value: FormStep.INFORMATION,
      //   label: <MetronicIcon iconType='Code' iconName='Info-circle' />,
      //   className: 'btn-icon',
      //   variant: getStepVariant(FormStep.INFORMATION),
      // },
      {
        value: FormStep.OTHER,
        label: <MetronicIcon iconType='Communication' iconName='Group' />,
        className: 'btn-icon',
        variant: getStepVariant(FormStep.OTHER),
      },
    ]
  }, [getStepVariant])

  const stepForm = useMemo(() => {
    switch (currentStep) {
      case FormStep.CUSTOMER: {
        return <RegistrationCustomerInformation formik={formik} />
      }
      // case FormStep.INFORMATION: {
      //   return <RegistrationPersonalInformation formik={formik} />
      // }
      case FormStep.OTHER: {
        return <RegistrationOtherInformation formik={formik} />
      }
    }
  }, [currentStep, formik])

  const handleNextClick = useCallback(() => {
    setCurrentStep((currentStep) => {
      return StepOrder[StepOrder.indexOf(currentStep) + 1]
    })
  }, [])

  const handlePreviousClick = useCallback(() => {
    setCurrentStep((currentStep) => {
      return StepOrder[StepOrder.indexOf(currentStep) - 1]
    })
  }, [])

  const stepHasErrors = useCallback(
    (step: FormStep): boolean => {
      let errors = formik.errors
      switch (step) {
        case FormStep.CUSTOMER: {
          errors = pick(formik.errors, STEP_CUSTOMER_KEYS)
          break
        }
        // case FormStep.INFORMATION: {
        //   errors = pick(formik.errors, STEP_INFORMATION_KEYS)
        //   break
        // }
        case FormStep.OTHER: {
          errors = pick(formik.errors, STEP_OTHER_KEYS)
          break
        }
      }
      return Object.values(errors).some((value) => Boolean(value))
    },
    [formik.errors]
  )

  // const assistantHasValues = useMemo(() => {
  //   const values = pick(formik.values, STEP_ASSISTANT_KEYS)
  //   return Object.values(values).some((value) => Boolean(value))
  // }, [formik.values])

  const otherHasValues = useMemo(() => {
    const values = pick(formik.values, STEP_OTHER_KEYS)
    return Object.values(values).some((value) => Boolean(value))
  }, [formik.values])

  const actions = useMemo(() => {
    const currentStepIndex = StepOrder.indexOf(currentStep)
    const hasPrevious = currentStepIndex > 0
    const hasNext = currentStepIndex < StepOrder.length - 1
    return (
      <>
        <div className='row mt-5 mb-3'>
          <div className='col col-xs-12 col-md'>
            {hasPrevious && (
              <Button className='w-100' variant='info' type='button' onClick={handlePreviousClick}>
                Previous
              </Button>
            )}
          </div>
          {hasNext && (
            <div className='col col-xs-12'>
              <Button
                className='w-100'
                variant='primary'
                type='button'
                disabled={stepHasErrors(currentStep)}
                onClick={handleNextClick}
              >
                Next
              </Button>
            </div>
          )}
          {!hasNext && (
            <div className='col-xs-12 col-md-6'>
              <Button
                className='w-100'
                variant='primary'
                type='submit'
                disabled={!otherHasValues || !formik.isValid || formik.isSubmitting}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
        <div className='row g-3'>
          {/* {!hasNext && (
            <div className='col-xs-12 col-md-6'>
              <Button
                className='w-100'
                variant='primary'
                type='submit'
                disabled={otherHasValues || !formik.isValid || formik.isSubmitting}
              >
                Skip and submit
              </Button>
            </div>
          )} */}
          {/* {!hasNext && (
            <div className='col-xs-12 col-md-6'>
              <Button
                className='w-100'
                variant='primary'
                type='submit'
                disabled={!otherHasValues || !formik.isValid || formik.isSubmitting}
              >
                Submit
              </Button>
            </div>
          )} */}
        </div>
      </>
    )
  }, [
    otherHasValues,
    currentStep,
    formik.isSubmitting,
    formik.isValid,
    handleNextClick,
    handlePreviousClick,
    stepHasErrors,
  ])

  const formTitle = useMemo(() => {
    if (!hasSubmitted) {
      let message: string = ''
      switch (currentStep) {
        case FormStep.CUSTOMER: {
          message = 'Register'
          break
        }
        // case FormStep.INFORMATION: {
        //   message = 'Enter your personal information'
        //   break
        // }
        case FormStep.OTHER: {
          return (
            <>
              <h1 className='text-dark mb-3'>Enter Other Details</h1>
              {/* <h1 className='text-dark mb-3'>(optional)</h1> */}
            </>
          )
        }
      }
      return <h1 className='text-dark mb-3'>{message}</h1>
    }
    return (
      <>
        <p className='text-gray-400 fw-bold fs-4'>Thank you for completing your registration</p>
        <p className='text-gray-400 fw-bold fs-4'>You will receive a confirmation email shortly</p>
      </>
    )
  }, [currentStep, hasSubmitted])

  const debouncedValidate = useDebounce(500)

  useOnChange(formik.values, () => {
    debouncedValidate(() => {
      formik.validateForm()
    })
  })

  useOnChange(hash, () => {
    resetFormValueFromHash()
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='text-center mb-10'>{formTitle}</div>
      {!hasSubmitted && (
        <>
          <div className='d-flex justify-content-center mb-5'>
            <ButtonCrumbs crumbs={crumbs} />
          </div>
          {stepForm}
          {actions}
        </>
      )}
    </form>
  )
}

const getFormData = (
  staticUrl: string,
  customer: Partial<CustomerModel>,
  fields: CustomFieldModel[],
  _customFieldValues?: CustomFieldCreateValue[]
): RegistrationFormValues => {
  //set the custom fields values to the custom fields of module
  const setFields: CustomFieldInputValue[] = fields.map((field) => ({
    isRequired: field.isRequired,
    isActive: field.isActive,
    type: field.type.code,
    customFieldCode: field.code,
    value: getFieldInitialValue(field.type.code, field.isMultipleOptions),
  }))

  if (_customFieldValues) {
    setFields.forEach((field) => {
      const value = _customFieldValues?.find(
        (value) => value.customFieldCode === field.customFieldCode
      )
      if (value) {
        field.value = value.value
      }
    })
  }

  const customerValues: RegistrationFormValues = {
    // title: '',
    name: customer.name || INITIAL_VALUES.name,
    // lastName: '',
    designation: customer.designation || INITIAL_VALUES.designation,
    customerOrganization: customer.customerOrganization || INITIAL_VALUES.customerOrganization,
    email: customer.email || INITIAL_VALUES.email,
    mobileNumber: customer.mobile || INITIAL_VALUES.mobileNumber,
    // dateOfBirth: customer.dob
    //   ? DateUtil.getDateFromApiString(customer.dob)
    //   : INITIAL_VALUES.dateOfBirth,
    // personalAssistantName: '',
    // personalAssistantEmail: '',
    // personalAssistantMobileNumber: '',
    // nationality: customer.nationality
    //   ? {code: customer.nationality, name: customer.nationality}
    //   : INITIAL_VALUES.nationality,
    passportPhoto: customer.photo
      ? new ImageInputValue(staticUrl, customer.photo)
      : INITIAL_VALUES.passportPhoto,
    passportPhotoCropped: INITIAL_VALUES.passportPhotoCropped,
    // residence: customer.residence
    //   ? {code: customer.residence, name: customer.residence}
    //   : INITIAL_VALUES.residence,
    // passportCopy: INITIAL_VALUES.passportCopy,
    // documentExpiry: INITIAL_VALUES.documentExpiry,
    // isFlight: INITIAL_VALUES.isFlight,
    // isHotel: INITIAL_VALUES.isHotel,
    // isTransportation: INITIAL_VALUES.isTransportation,
    customFields: setFields,
  }

  return customerValues
}

const getPayload = (hash: string, customer: RegistrationFormValues) => {
  const formData = new FormData()
  const photo = customer.passportPhoto?.toApiValue()
  const photoCropped = customer.passportPhotoCropped?.toApiValue()
  formData.append('hash', hash)
  formData.append('name', customer.name)
  formData.append('email', customer.email)
  formData.append('mobile', customer.mobileNumber)
  // formData.append('residence', customer.residence?.code || '')
  formData.append('designation', customer.designation)
  formData.append('customerOrganization', customer.customerOrganization)
  // customer.documentExpiry &&
  //   formData.append('documentExpiry', DateUtil.convertDateToApiString(customer.documentExpiry))
  // formData.append('nationality', customer.nationality?.code || '')
  // customer.dateOfBirth &&
  //   formData.append('dob', DateUtil.convertDateToApiString(customer.dateOfBirth))

  photoCropped && formData.append('photo', photoCropped, 'photo.png')
  photo && formData.append('documents', photo, 'photo.png')
  photo && formData.append('photo', photo, 'photo.png')
  // customer.passportCopy.getFileArray().forEach((file) => {
  //   const passport = file.toApiValue()
  //   passport && formData.append('documents', passport)
  // })

  // formData.append(`personalAssistant[name]`, customer.personalAssistantName)
  // formData.append(`personalAssistant[email]`, customer.personalAssistantEmail)
  // formData.append(`personalAssistant[mobile]`, customer.personalAssistantMobileNumber)

  // const type: Array<string> = []
  // customer.isFlight && type.push('flight')
  // customer.isHotel && type.push('hotel')
  // customer.isTransportation && type.push('transportation')

  // type.forEach((type, index) => {
  //   formData.append(`services[${index}][type]`, type)
  // })
  customer.customFields && formData.append('customFields', JSON.stringify(customer.customFields))

  return formData
}

const INITIAL_VALUES: RegistrationFormValues = {
  // title: '',
  email: '',
  name: '',
  // lastName: '',
  mobileNumber: '',
  // dateOfBirth: null,
  designation: '',
  // nationality: null,
  // residence: null,
  customerOrganization: '',
  passportPhoto: null,
  passportPhotoCropped: null,
  // documentExpiry: null,
  // passportCopy: new FileInputValueCollection<BaseFileInputValue>(),
  // personalAssistantName: '',
  // personalAssistantEmail: '',
  // personalAssistantMobileNumber: '',
  // isFlight: false,
  // isHotel: false,
  // isTransportation: false,
  customFields: [],
}

const validationSchema = yup.object().shape({
  // title: yup.string(),
  email: yup.string().email('Please enter a valid email'),
  name: yup.string(),
  // lastName: yup.string(),
  mobileNumber: yup
    .string()
    .test('is-mobile-number', 'Please enter a valid mobile number', (value) => {
      if (value) {
        const mobileNumber = new MobileNumberParser(value)
        return mobileNumber.isValidMobileNumber()
      }
      return true
    })
    .required('Please enter a valid number'),
  // dateOfBirth: yup.date().required('Please enter your date of birth.'),
  designation: yup.string().required('Please enter position'),
  // nationality: yup
  //   .object()
  //   .typeError('Please enter your nationality.')
  //   .required('Please enter your nationality'),
  // residence: yup
  //   .object()
  //   .typeError('Please select your residence')
  //   .required('Please select your residence'),
  customerOrganization: yup.string().required('Please enter company or organization'),
  passportPhoto: yup
    .object()
    .required('Please submit your photo')
    .typeError('Please submit your photo'),
  // documentExpiry: yup.date().required('Please enter expiry date.'),
  // passportCopy: yup
  //   .mixed()
  //   .required('Please submit your passport')
  //   .test('has-value', 'Please submit your passport', (value) => {
  //     if (value instanceof FileInputValueCollection) {
  //       return value.hasValue()
  //     }
  //     return false
  //   }),
  // personalAssistantName: yup.string(),
  // personalAssistantEmail: yup.string(),
  // personalAssistantMobileNumber: yup
  //   .string()
  //   .test('is-mobile-number', 'Please enter a valid mobile number', (value) => {
  //     if (value) {
  //       const mobileNumber = new MobileNumberParser(value)
  //       return mobileNumber.isValidMobileNumber()
  //     }
  //     return true
  //   }),
  customFields: yup
    .array()
    .test('check-fields', 'Invalid field values.', (fields?: CustomFieldInputValue[]) => {
      if (fields) {
        const isValid = fields.every((field) => {
          if (field.isActive && field.isRequired && field.type === 'STRING' && field.value !== '') {
            return Boolean(field.value)
          } else if (
            field.isActive &&
            field.isRequired &&
            field.type === 'INT' &&
            field.value !== ''
          ) {
            return Boolean(field.value)
          } else if (
            field.isActive &&
            field.isRequired &&
            field.type === 'DATE' &&
            field.value !== ''
          ) {
            return Boolean(field.value)
          } else if (
            field.isActive &&
            field.isRequired &&
            field.type === 'OPTIONS' &&
            Array.isArray(field.value)
          ) {
            if (field.value[0] === undefined || field.value[0] === '') {
              return false
            } else return Boolean(field.value)
          }
          return true
        })
        return isValid
      }
      return false
    }),
})
