import {Redirect, Route, Switch} from 'react-router-dom'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import { OpenRegistrationPage } from '../../modules/open-registration/pages/OpenRegistrationPage'

export const Routes = () => {
  return (
    <Switch>
      <Route path='/auth/register' component={OpenRegistrationPage} />
      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      <Route path='/error/403'>
        <FullPage403 link='https://solaax.com' />
      </Route>
      <Redirect to='/auth/register' />
    </Switch>
  )
}
