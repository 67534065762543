/* eslint-disable no-lone-blocks */
import {useCallback, useMemo, useState} from 'react'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {ButtonCrumbAttributes} from '../../../../components/ButtonCrumbs/ButtonCrumb'
import {ButtonCrumbs} from '../../../../components/ButtonCrumbs/ButtonCrumbs'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {Button, ButtonVariant} from '../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {pick} from 'lodash'
import {MobileNumberParser} from '../../../../utils/MobileNumberParser'
import {useDebounce} from '../../../../components/hooks/useDebounce'
// import {CustomerModel} from '../../../../models/CustomerModel'
// import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
// import {useAppConfig} from '../../../app-config/hooks/useAppConfig'
// import {useCustomFieldTableHelpers} from '../../../default/system/components/tables/CustomFieldTable/useCustomFieldTableHelpers'
import {CustomFieldInputValue} from '../../../default/system/components/TypeFieldInput/CustomFieldTypeInput'
// import {CustomFieldCreateValue, CustomFieldModel} from '../../../../models/system/CustomFieldModel'
// import {getFieldInitialValue} from '../../../default/system/hooks/useCustomFieldFormFields'
import { OpenRegistrationCustomerInformation, OpenRegistrationCustomerInformationValues, STEP_CUSTOMER_KEYS } from './steps/OpenRegistrationCustomerInformation'
import { OpenRegistrationOtherInformation, OpenRegistrationOtherInformationValues, STEP_OTHER_KEYS } from './steps/OpenRegistrationOtherInformation'
import { CreateOpenRegistration } from '../redux/OpenRegistrationCrud'
import { EVENT_CODE } from '../../../../../config/env'



enum FormStep {
  CUSTOMER = 'CUSTOMER',
  OTHER = 'OTHER',
}

const StepOrder = [FormStep.CUSTOMER, FormStep.OTHER]

export interface OpenRegistrationFormValues
  extends OpenRegistrationCustomerInformationValues,
    OpenRegistrationOtherInformationValues {}


export const OpenRegistration = () => {
  const [currentStep, setCurrentStep] = useState(FormStep.CUSTOMER)
  const {pushError} = useAlerts()
  const [hasSubmitted, setHasSubmitted] = useState(false)
//   const {staticUrls} = useAppConfig()
//   const [_data, setData] = useState<Partial<CustomerModel>>(INITIAL_VALUES)

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
        if(EVENT_CODE){

            try {
              const payload = getPayload(values, EVENT_CODE)
              await CreateOpenRegistration(payload)
              setHasSubmitted(true)
            } catch (e: any) {
              pushError(e)
            }
        }

    },
    validationSchema,
    validateOnMount: true,
  })
//   const {customFields} = useCustomFieldTableHelpers({
//     selectedTable: 'CUSTOMER',
//     isPortal: true,
//   })

//   const resetFormValueFromHash = useCallback(async () => {
//     try {
//       if (hash) {
//         const {data} = await VerifyOpenRegistrationHash(hash)
//         setData(data)
//         const formData = getFormData(
//           organizationCode ? staticUrls.public : `${staticUrls.public}/${data.organization?.code}`,
//           data,
//           customFields,
//           data.customFieldValues
//         )

//         formik.setValues(formData)
//       } else {
//         throw new Error('Invalid hash')
//       }
//     } catch (e: any) {
//       pushError(e)
//       history.replace('/error/403')
//     }
//   }, [customFields, formik, hash, history, organizationCode, pushError, staticUrls.public])


//   useEffect(() => {
//     if (customFields && _data) {
//       const customFieldsWithValues = mapCustomFieldValuesToCustomFields(customFields, _data.customFieldValues || [])

//       const initialValuesWithCustomFields = {
//         ...INITIAL_VALUES,
//         firstName: _data.firstName || INITIAL_VALUES.firstName,
//         lastName: _data.lastName || INITIAL_VALUES.lastName,
//         email: _data.email || INITIAL_VALUES.email,
//         designation: _data.designation || INITIAL_VALUES.designation,
//         customerOrganization: _data.customerOrganization || INITIAL_VALUES.customerOrganization,
//         mobileNumber: _data.mobile || INITIAL_VALUES.mobileNumber,
//         passportPhoto: _data.photo ? new ImageInputValue(staticUrls.public, _data.photo) : INITIAL_VALUES.passportPhoto,
//         passportPhotoCropped: INITIAL_VALUES.passportPhotoCropped,
//         customFields: customFieldsWithValues,
//       }
  
//       formik.resetForm({ values: initialValuesWithCustomFields })
//     }
//     //dont add dependencies
//   }, [customFields, _data])


//   const mapCustomFieldValuesToCustomFields = (
//     customFields: CustomFieldModel[],
//     customFieldValues: CustomFieldCreateValue[]
//   ): CustomFieldInputValue[] => {
//     return customFields.map((field) => {
//       const fieldValue = customFieldValues.find(value => value.customFieldCode === field.code)
//       return {
//         customFieldCode: field.code, 
//         value: fieldValue ? fieldValue.value : getFieldInitialValue(field.type.code, field.isMultipleOptions),
//         type: field.type.code, 
//         isRequired: field.isRequired,
//         isActive: field.isActive,
//       }
//     })
//   }
  

  const getStepVariant = useCallback(
    (step: FormStep): ButtonVariant => {
      const isPrevious = StepOrder.indexOf(step) < StepOrder.indexOf(currentStep)
      if (isPrevious) {
        return 'success'
      }
      if (currentStep === step) {
        return 'primary'
      }
      return 'default'
    },
    [currentStep]
  )

  const crumbs = useMemo((): ButtonCrumbAttributes[] => {
    return [
      {
        value: FormStep.CUSTOMER,
        label: <MetronicIcon iconType='General' iconName='User' />,
        className: 'btn-icon',
        variant: getStepVariant(FormStep.CUSTOMER),
      },
      {
        value: FormStep.OTHER,
        label: <MetronicIcon iconType='Communication' iconName='Group' />,
        className: 'btn-icon',
        variant: getStepVariant(FormStep.OTHER),
      },
    ]
  }, [getStepVariant])

  const stepForm = useMemo(() => {
    switch (currentStep) {
      case FormStep.CUSTOMER: {
        return <OpenRegistrationCustomerInformation formik={formik} />
      }
      case FormStep.OTHER: {
        return <OpenRegistrationOtherInformation formik={formik} />
      }
    }
  }, [currentStep, formik])

  const handleNextClick = useCallback(() => {
    setCurrentStep((currentStep) => {
      return StepOrder[StepOrder.indexOf(currentStep) + 1]
    })
  }, [])

  const handlePreviousClick = useCallback(() => {
    setCurrentStep((currentStep) => {
      return StepOrder[StepOrder.indexOf(currentStep) - 1]
    })
  }, [])

  const stepHasErrors = useCallback(
    (step: FormStep): boolean => {
      let errors = formik.errors
      switch (step) {
        case FormStep.CUSTOMER: {
          errors = pick(formik.errors, STEP_CUSTOMER_KEYS)
          break
        }
        case FormStep.OTHER: {
          errors = pick(formik.errors, STEP_OTHER_KEYS)
          break
        }
      }
      return Object.values(errors).some((value) => Boolean(value))
    },
    [formik.errors]
  )

  const otherHasValues = useMemo(() => {
    const values = pick(formik.values, STEP_OTHER_KEYS)
    return Object.values(values).some((value) => Boolean(value))
  }, [formik.values])

  const actions = useMemo(() => {
    const currentStepIndex = StepOrder.indexOf(currentStep)
    const hasPrevious = currentStepIndex > 0
    const hasNext = currentStepIndex < StepOrder.length - 1
    return (
      <>
        <div className='row mt-5 mb-3'>
          <div className='col col-xs-12 col-md'>
            {hasPrevious && (
              <Button className='w-100' variant='info' type='button' onClick={handlePreviousClick}>
                Previous
              </Button>
            )}
          </div>
          {hasNext && (
            <div className='col col-xs-12'>
              <Button
                className='w-100'
                variant='primary'
                type='button'
                disabled={stepHasErrors(currentStep)}
                onClick={handleNextClick}
              >
                Next
              </Button>
            </div>
          )}
          {!hasNext && (
            <div className='col-xs-12 col-md-6'>
              <Button
                className='w-100'
                variant='primary'
                type='submit'
                disabled={!otherHasValues || !formik.isValid || formik.isSubmitting}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
        <div className='row g-3'>
        </div>
      </>
    )
  }, [
    otherHasValues,
    currentStep,
    formik.isSubmitting,
    formik.isValid,
    handleNextClick,
    handlePreviousClick,
    stepHasErrors,
  ])

  const formTitle = useMemo(() => {
    if (!hasSubmitted) {
      let message: string = ''
      switch (currentStep) {
        case FormStep.CUSTOMER: {
          message = 'Register'
          break
        }
        case FormStep.OTHER: {
          return (
            <>
              <h1 className='text-dark mb-3'>Enter Other Details</h1>
            </>
          )
        }
      }
      return <h1 className='text-dark mb-3'>{message}</h1>
    }
    return (
      <>
        <p className='text-gray-400 fw-bold fs-4'>Thank you for completing your registration</p>
        <p className='text-gray-400 fw-bold fs-4'>You will receive a confirmation email shortly</p>
      </>
    )
  }, [currentStep, hasSubmitted])

  const debouncedValidate = useDebounce(500)

  useOnChange(formik.values, () => {
    debouncedValidate(() => {
      formik.validateForm()
    })
  })

//   useOnChange(hash, () => {
//     resetFormValueFromHash()
//   })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='text-center mb-10'>{formTitle}</div>
      {!hasSubmitted && (
        <>
          <div className='d-flex justify-content-center mb-5'>
            <ButtonCrumbs crumbs={crumbs} />
          </div>
          {stepForm}
          {actions}
        </>
      )}
    </form>
  )
}

// const getFormData = (
//   staticUrl: string,
//   customer: Partial<CustomerModel>,
//   fields: CustomFieldModel[],
//   _customFieldValues?: CustomFieldCreateValue[]
// ): OpenRegistrationFormValues => {
//   //set the custom fields values to the custom fields of module
//   const setFields: CustomFieldInputValue[] = fields.map((field) => ({
//     isRequired: field.isRequired,
//     isActive: field.isActive,
//     type: field.type.code,
//     customFieldCode: field.code,
//     value: getFieldInitialValue(field.type.code, field.isMultipleOptions),
//   }))

//   if (_customFieldValues) {
//     setFields.forEach((field) => {
//       const value = _customFieldValues?.find(
//         (value) => value.customFieldCode === field.customFieldCode
//       )
//       if (value) {
//         field.value = value.value
//       }
//     })
//   }

//   const customerValues: OpenRegistrationFormValues = {
//     firstName: customer.firstName || INITIAL_VALUES.firstName,
//     lastName: customer.lastName || INITIAL_VALUES.lastName,
//     designation: customer.designation || INITIAL_VALUES.designation,
//     customerOrganization: customer.customerOrganization || INITIAL_VALUES.customerOrganization,
//     email: customer.email || INITIAL_VALUES.email,
//     mobileNumber: customer.mobile || INITIAL_VALUES.mobileNumber,

//     passportPhoto: customer.photo
//       ? new ImageInputValue(staticUrl, customer.photo)
//       : INITIAL_VALUES.passportPhoto,
//     passportPhotoCropped: INITIAL_VALUES.passportPhotoCropped,
//     customFields: setFields,
//   }

//   return customerValues
// }

export const getPayload = ( customer: OpenRegistrationFormValues, eventCode: string, hash?: string,) => {
  const formData = new FormData()
  const photo = customer.passportPhoto?.toApiValue()

  const photoCropped = customer.passportPhotoCropped?.toApiValue()
  hash && formData.append('hash', hash)
  formData.append('eventCode', eventCode)
  formData.append('firstName', customer.firstName)
  formData.append('lastName', customer.lastName)
  formData.append('email', customer.email)
  formData.append('mobile', customer.mobileNumber)
  formData.append('designation', customer.designation)
  formData.append('customerOrganization', customer.customerOrganization)
  photoCropped && formData.append('photo', photoCropped, 'photo.png')
  photo && formData.append('documents', photo, 'photo.png')
  photo && formData.append('photo', photo, 'photo.png')
  customer.customFields && formData.append('customFields', JSON.stringify(customer.customFields))

  return formData
}

export const INITIAL_VALUES: OpenRegistrationFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  designation: '',
  customerOrganization: '',
  passportPhoto: null,
  passportPhotoCropped: null,
  customFields: [],
}
export const validationSchema = yup.object().shape({
  email: yup.string().required('Please enter an email').email('Please enter a valid email'),
  firstName: yup.string().required('Please enter first name'),
  lastName: yup.string().required('Please enter last name'),
  mobileNumber: yup
    .string()
    .test('is-mobile-number', 'Please enter a valid mobile number', (value) => {
      if (value) {
        const mobileNumber = new MobileNumberParser(value)
        return mobileNumber.isValidMobileNumber()
      }
      return true
    })
    .required('Please enter a valid number'),
  designation: yup.string().required('Please enter Professional Title/Position'),
  customerOrganization: yup.string().required('Please enter Company/Organization'),
  passportPhoto: yup
    .object()
    .required('Please submit your photo')
    .typeError('Please submit your photo'),
  customFields: yup
    .array()
    .test('check-fields', 'Invalid field values.', (fields?: CustomFieldInputValue[]) => {
      if (fields) {
        const isValid = fields.every((field) => {
          if (field.isActive && field.isRequired && field.type === 'STRING' && field.value !== '') {
            return Boolean(field.value)
          } else if (
            field.isActive &&
            field.isRequired &&
            field.type === 'INT' &&
            field.value !== ''
          ) {
            return Boolean(field.value)
          } else if (
            field.isActive &&
            field.isRequired &&
            field.type === 'DATE' &&
            field.value !== ''
          ) {
            return Boolean(field.value)
          } else if (
            field.isActive &&
            field.isRequired &&
            field.type === 'OPTIONS' &&
            Array.isArray(field.value)
          ) {
            if (field.value[0] === undefined || field.value[0] === '') {
              return false
            } else return Boolean(field.value)
          }
          return true
        })
        return isValid
      }
      return false
    }),
})
