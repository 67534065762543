import {FormikContextType} from 'formik'
import {Fragment} from 'react'
import { useCustomFieldFormFields } from '../../../../default/system/hooks/useCustomFieldFormFields'
import { CustomFieldInputValue } from '../../../../default/system/components/TypeFieldInput/CustomFieldTypeInput'
import { CustomFieldCreateValue } from '../../../../../models/system/CustomFieldModel'

export interface OpenRegistrationOtherInformationValues {
  customFields: CustomFieldInputValue[]
  customFieldValues?:  CustomFieldCreateValue[]
}

export interface OpenRegistrationOtherInformationProps<T extends OpenRegistrationOtherInformationValues> {
  formik: FormikContextType<T>
}

export const OpenRegistrationOtherInformation = <T extends OpenRegistrationOtherInformationValues>({
  formik,
}: OpenRegistrationOtherInformationProps<T>) => {
    
//Get all custom fields with its properties
  const {inputFields} = useCustomFieldFormFields({
    formik,
    tableCode: 'CUSTOMER',
    isPortal: true
  })

  return (
    <div className='row'>
         {inputFields && (
        <>
          {Object.entries(inputFields).map(([key, field]) => (
            <Fragment key={key}>{field}</Fragment>
          ))}
        </>
      )}
    </div>
  )
}


export const STEP_OTHER_KEYS: Array<keyof OpenRegistrationOtherInformationValues> = [
  'customFields',

]
