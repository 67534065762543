import {FormikContextType} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {useLocalTableSearch} from '../../hooks/useLocalTableSearch'
import {useOnChange} from '../../hooks/useOnChange'
import {ColumnStyle} from '../../tables/constants/ColumnStyle'
import {TableColumnOptions} from '../../tables/TableColumn'
import {map} from 'lodash'
import {FilterTable} from '../../tables/FilterTable'
import {idExtractor} from '../../../utils/idExtractor'
import {useTableOptions} from '../../tables/useTableOptions'
import {
  SeatMapSelectionModalFormValues,
  getBulkSeatMapPayload,
} from '../../forms/SeatMapSelectionModalForm'
import {useModalState} from '../../modals/useModalState'
import {CustomersProductTableActions} from './CustomersProductTableActions'
import {useLoadingState} from '../../hooks/useLoadingState'
import {
  BookingWizardFinalSeatsStepFormValues,
  BulkBookingFormValues,
  CustomersProductProps,
} from '../../../models/booking-wizard/BulkBookingWizard'

import {BookingSeatMap} from '../../BookingWizard/component/bulkBooking/BookingSeatMap'
import {TicketModelFulfillBulkParams} from '../../../models/ems/TicketModel'
import {SeatMapValue} from '../../inputs/SeatMapInput/SeatMapValue'
import {SeatTableColumn} from '../component/SeatTableColumn'
import {FinalStepProductHeader} from '../component/FinalStepProductHeader'
import {DateUtil} from '../../../utils/DateUtil'
import {dateIsBetween} from './BookingWizardSharedFinalizeStep'

export interface BookingWizardSharedBulkFinalizeStepProps<
  T extends BookingWizardFinalSeatsStepFormValues
> {
  formik: FormikContextType<T>
  bookingBulkForm?: BulkBookingFormValues
  customerCode?: string
}

export const BookingWizardSharedBulkFinalizeStep = <
  T extends BookingWizardFinalSeatsStepFormValues
>({
  formik,
  bookingBulkForm,
  customerCode,
}: BookingWizardSharedBulkFinalizeStepProps<T>) => {
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'product-booking-detail',
  })

  const [productsSeats, setProductsSeats] = useState<TicketModelFulfillBulkParams[]>([])
  const [activeProduct, setActiveProduct] = useState<CustomersProductProps | null>(null)
  const {getModalState, open: openModal, hide: hideModal} = useModalState()
  const {isKeyLoading} = useLoadingState()

  useOnChange(bookingBulkForm, () => {
    if (bookingBulkForm) {
      formik.setFieldValue('customers', bookingBulkForm.customers)
      formik.setFieldValue('product', bookingBulkForm.product)
      formik.setFieldValue('voucher', bookingBulkForm.voucher)
      formik.setFieldValue('eventCode', bookingBulkForm.eventCode)
      formik.setFieldValue('customersSeats', bookingBulkForm.customersSeats)
    }
  })

  const selectedSeats = useMemo(() => {
    if (formik.values.customersSeats && formik.values.customersSeats.length && activeProduct) {
      const found = formik.values.customersSeats.find(
        (item) =>
          item.customerCode === activeProduct.code &&
          (dateIsBetween(
            DateUtil.getDateFromApiString(activeProduct.startedAt),
            DateUtil.getDateFromApiString(item.startedAt),
            DateUtil.getDateFromApiString(item.endedAt)
          ) ||
            dateIsBetween(
              DateUtil.getDateFromApiString(activeProduct.endedAt),
              DateUtil.getDateFromApiString(item.startedAt),
              DateUtil.getDateFromApiString(item.endedAt)
            ))
      )
      if (found) return found
    }
    return undefined
  }, [activeProduct, formik.values.customersSeats])

  const otherSeats = useMemo(() => {
    if (formik.values.customersSeats && formik.values.customersSeats.length && activeProduct) {
      const found = formik.values.customersSeats.filter(
        (item) => item.customerCode !== activeProduct.code
      )
      if (found) return found
    }
    return undefined
  }, [activeProduct, formik.values.customersSeats])

  const isProduct = useMemo(() => {
    return formik.values.product
  }, [formik.values.product])

  const customersProductsVouchers = useMemo<CustomersProductProps[] | null>(() => {
    if (formik.values.customers && formik.values.customers.length) {
      const data: CustomersProductProps[] = formik.values.customers.map((customer) => {
        return {
          code: customer.code,
          name: customer.name,
          productCode:
            (isProduct ? formik.values.product?.code : formik.values.voucher?.code) || '',
          productName:
            (isProduct ? formik.values.product?.name : formik.values.voucher?.name) || '',
          productType:
            (isProduct ? formik.values.product?.type : formik.values.voucher?.type) || 'product',
          productQty: formik.values.product?.qty || 0,
          productIsSeated: formik.values.product?.isSeated || false,
          startedAt: formik.values.product?.startedAt || '',
          endedAt: formik.values.product?.endedAt || '',
        }
      })
      if (data) {
        return data
      }
    }

    if (formik.values.customer) {
      return [
        {
          code: formik.values.customer.code,
          name: formik.values.customer.name,
          productCode:
            (isProduct ? formik.values.product?.code : formik.values.voucher?.code) || '',
          productName:
            (isProduct ? formik.values.product?.name : formik.values.voucher?.name) || '',
          productType:
            (isProduct ? formik.values.product?.type : formik.values.voucher?.type) || 'product',
          productQty: formik.values.product?.qty || 0,
          productIsSeated: formik.values.product?.isSeated || false,
          startedAt: formik.values.product?.startedAt || '',
          endedAt: formik.values.product?.endedAt || '',
        },
      ]
    }
    return null
  }, [
    formik.values.customer,
    formik.values.customers,
    formik.values.product?.code,
    formik.values.product?.endedAt,
    formik.values.product?.isSeated,
    formik.values.product?.name,
    formik.values.product?.qty,
    formik.values.product?.startedAt,
    formik.values.product?.type,
    formik.values.voucher?.code,
    formik.values.voucher?.name,
    formik.values.voucher?.type,
    isProduct,
  ])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<CustomersProductProps>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'name',
        label: 'Customer Name',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'customerSeats',
        label: 'Seats',
        sortable: true,
        hideable: true,
        render: ({data}) => {
          return <SeatTableColumn customersSeats={formik.values.customersSeats} data={data} />
        },
      },
    ]
    return columns
  }, [formik.values.customersSeats])

  const {searchableLocalTableData, filterSearchableLocalTableData} = useLocalTableSearch({
    data: customersProductsVouchers,
    columns: customersProductsVouchers
      ? (map(columns, 'field') as Array<keyof CustomersProductProps>)
      : null,
    additionColumns: ['productType', 'productCode', 'productName', 'productQty', 'productIsSeated'],
  })

  const getSeatAssignmentHandler = useCallback(
    async (data: CustomersProductProps) => {
      setActiveProduct(data)
      openModal()
    },
    [openModal]
  )

  const rowActions = useCallback(
    (data: CustomersProductProps) => (
      <CustomersProductTableActions
        data={data}
        onAssignSeats={getSeatAssignmentHandler}
        // onFulfillNonSeatedProduct={fulfillNonSeatedProduct}
        // onFulfillVoucher={fulfillVoucher}
        loading={isKeyLoading(data.code)}
      />
    ),
    [getSeatAssignmentHandler, isKeyLoading]
  )

  useOnChange(productsSeats, () => {
    formik.setFieldValue('customersSeats', productsSeats)
  })
  const handleSeatsSelection = useCallback(
    async (values: SeatMapSelectionModalFormValues) => {
      if (activeProduct && activeProduct.productCode) {
        const payload = getBulkSeatMapPayload(
          values,
          activeProduct.productCode,
          activeProduct.code,
          activeProduct.startedAt,
          activeProduct.endedAt
        )
        if (payload) {
          let newSeates = [...productsSeats]
          newSeates = newSeates.filter((item) => item.customerCode !== payload.customerCode)
          newSeates.push(payload)
          setProductsSeats(newSeates)
          hideModal()
        }
      }
    },
    [activeProduct, hideModal, productsSeats]
  )

  return (
    <div className='container'>
      <div>
        <FinalStepProductHeader product={formik.values.product} />
        <FilterTable
          onFilter={filterSearchableLocalTableData}
          idExtractor={idExtractor}
          onHiddenColumnsChange={setHiddenColumns}
          hiddenColumns={hiddenColumns}
          data={searchableLocalTableData.data}
          currentPageNumber={searchableLocalTableData?.page}
          columns={columns}
          totalItems={searchableLocalTableData?.total}
          actions={rowActions}
        />
        <BookingSeatMap
          onSubmit={handleSeatsSelection}
          product={activeProduct}
          eventCode={formik.values.eventCode}
          customerCode={customerCode}
          selectedSeat={selectedSeats}
          otherSeats={otherSeats}
          initialValues={{
            locationCode: selectedSeats?.locationCode || '',
            selected: selectedSeats?.seats || new SeatMapValue(),
          }}
          {...getModalState()}
        />
      </div>
    </div>
  )
}
