import {MetronicIconPath} from '../app/components/inputs/MetronicIcon'

export interface NavigationSection {
  title?: string
  menus: NavigationSectionMenuItem[]
}

export type NavigationSectionMenuItemStatus = 'danger' | 'warning' | 'success'

export interface NavigationSectionMenuItem {
  title: string
  intlId?: string
  to?: string
  icon?: string | MetronicIconPath
  subMenus?: NavigationSectionMenuItem[]
  status?: NavigationSectionMenuItemStatus
}

export interface AuthNavigationSectionMenuItem extends NavigationSectionMenuItem {
  isAdminOnly?: boolean
  subMenus?: AuthNavigationSectionMenuItem[]
}

export const CUSTOMER_PORTAL_MENU: AuthNavigationSectionMenuItem = {
  title: 'Customers',
  to: '/customer',
}

export const EVENTS_PORTAL_MENU: AuthNavigationSectionMenuItem = {
  title: 'Events',
  to: '/event',
}
export const CUSTOMER_DELEGATE_MENU: AuthNavigationSectionMenuItem = {
  title: '',
  to: '',
}

export const PORTA_DEFAULT_LAYOUT_MENUS = [EVENTS_PORTAL_MENU, CUSTOMER_PORTAL_MENU]
export const DELEGATE_MENU = [CUSTOMER_DELEGATE_MENU]
