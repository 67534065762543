import {FormikContextType} from 'formik'
import {useCallback, useMemo} from 'react'
import {useTranslationFieldHelpers} from '../../../../components/inputs/hooks/useTranslationFieldHelpers'
import {useCustomFieldTableHelpers} from '../components/tables/CustomFieldTable/useCustomFieldTableHelpers'
import {
  CustomFieldInputValue,
  CustomFieldTypeInput,
} from '../components/TypeFieldInput/CustomFieldTypeInput'

export interface CustomFieldFormValues {
  customFields: CustomFieldInputValue[]
}

export interface UseCustomFieldFormFields<T extends CustomFieldFormValues> {
  formik: FormikContextType<T>
  tableCode: string
  containerType?: string
  lang?: string
  isPortal?: boolean
}

export const useCustomFieldFormFields = <T extends CustomFieldFormValues>({
  formik,
  tableCode,
  containerType,
  lang,
  isPortal = false,
}: UseCustomFieldFormFields<T>) => {
  const {customFields, handleTranslationChange} = useCustomFieldTableHelpers({
    selectedTable: tableCode,
    isPortal
  })

  const handleCustomFieldsChange = useCallback(
    (values: CustomFieldInputValue[]) => {
      if (lang) {
        handleTranslationChange(formik, lang, values)
      } else {
        formik.setFieldValue('customFields', values)
      }
    },
    [formik, handleTranslationChange, lang]
  )

  const {getTranslationTextFieldProps} = useTranslationFieldHelpers(formik, lang)
  const translatedData = getTranslationTextFieldProps('customFieldValues')

  const inputFields = useMemo(() => {
    return {
      customFields: (
        <>
          {customFields && (
            <CustomFieldTypeInput
              fields={customFields}
              onChange={handleCustomFieldsChange}
              value={
                lang && Array.isArray(translatedData.value)
                  ? translatedData.value
                  : formik.values.customFields
              }
              containerType={containerType}
            />
          )}
        </>
      ),
    }
  }, [
    containerType,
    customFields,
    formik.values.customFields,
    handleCustomFieldsChange,
    lang,
    translatedData.value,
  ])

  return {inputFields}
}

export const getFieldInitialValue = (type: string, isMultipleOptions?: boolean) => {
  if (type === 'BOOLEAN') {
    return false
  }
  if (type === 'STRING') {
    return ''
  }
  if (type === 'OPTIONS' && isMultipleOptions) {
    return ''
  }
  if (type === 'OPTIONS' && !isMultipleOptions) {
    return []
  }
  if (type === 'INT') {
    return 0
  }
  if (type === 'DATE') {
    return null
  }
  return null
}
