import {ReactNode, useCallback, useMemo, useState} from 'react'
import {FilterModel} from '../../models/FilterModel'
import {GlobalSearchModel} from '../../models/GlobalSearchModel'
import {TableOption} from '../../modules/default/system/redux/SystemRedux'
import {HasCode, idExtractor} from '../../utils/idExtractor'
import {SwalUtils} from '../../utils/SwalUtils'
import {Button} from '../inputs/Button'
import {MetronicIcon} from '../inputs/MetronicIcon'
import {ManageRowAction} from './actions/ManageRowAction'
import {TableSelectionAction} from './actions/TableSelectionAction'
import {FilterTable} from './FilterTable'
import {TableColumnOptions} from './TableColumn'
import {TableRowId} from './TableRow'
import {useTableOptions} from './useTableOptions'

export interface BasicTableProps<T> {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<T>
  onEdit?: (data: T) => void
  onDelete?: (codes: string[]) => void
  onCreate?: () => void
  columns: TableColumnOptions<T>[]
  name: string
  tableOptionName?: string
  defaultTableOptions?: Partial<TableOption>
  canDeleteRow?: (data: T) => boolean
  canEditRow?: (data: T) => boolean
  advancedFilters?: ReactNode
  initialFilters?: FilterModel
  hideSelections?: boolean
  noPagination?: boolean
}

export const BasicTable = <T extends HasCode>({
  onFilter,
  data,
  onDelete,
  onEdit,
  onCreate,
  columns,
  name,
  tableOptionName,
  defaultTableOptions,
  canDeleteRow,
  canEditRow,
  advancedFilters,
  initialFilters,
  hideSelections,
  noPagination,
}: BasicTableProps<T>) => {
  const [selected, setSelected] = useState<TableRowId[]>([])
  const {setHiddenColumns, hiddenColumns} = useTableOptions({
    tableName: tableOptionName,
    defaults: defaultTableOptions,
  })

  const deleteByCodes = useCallback(
    async (codes: string[]) => {
      if (onDelete) {
        const response = await SwalUtils.deleteItem()
        if (response.isConfirmed) {
          onDelete(codes)
        }
      }
    },
    [onDelete]
  )

  const handleDeleteOne = useCallback(
    (item: T) => {
      deleteByCodes([item.code])
    },
    [deleteByCodes]
  )

  const handleDeleteBulk = useCallback(
    (items: T[]) => {
      deleteByCodes(items.map((item) => item.code))
    },
    [deleteByCodes]
  )

  const rowActions = useMemo(() => {
    if (onEdit || onDelete) {
      return (data: T) => {
        const canEdit = canEditRow ? canEditRow(data) : true
        const canDelete = canDeleteRow ? canDeleteRow(data) : true
        const editHandler = canEdit ? onEdit : undefined
        const deleteHandler = canDelete && onDelete ? handleDeleteOne : undefined
        return <ManageRowAction data={data} onDelete={deleteHandler} onEdit={editHandler} />
      }
    }
  }, [canDeleteRow, canEditRow, handleDeleteOne, onDelete, onEdit])

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  return (
    <FilterTable
      noPagination={noPagination}
      initialFilters={initialFilters}
      selectionAction={
        onDelete && (
          <TableSelectionAction
            idExtractor={idExtractor}
            items={tableItems}
            variant='danger'
            onClick={handleDeleteBulk}
            selected={selected}
          >
            Delete
          </TableSelectionAction>
        )
      }
      rightToolbar={
        onCreate && (
          <Button uppercase={false} variant='primary' onClick={onCreate}>
            <MetronicIcon iconType='Navigation' iconName='Plus' />
            New {name}
          </Button>
        )
      }
      hiddenColumns={tableOptionName ? hiddenColumns : undefined}
      onHiddenColumnsChange={tableOptionName ? setHiddenColumns : undefined}
      onFilter={onFilter}
      idExtractor={idExtractor}
      data={tableItems}
      currentPageNumber={data?.page}
      columns={columns}
      totalItems={data?.total || 0}
      actions={rowActions}
      selection={hideSelections ? undefined : selected}
      onSelectionChange={setSelected}
      advancedFilters={advancedFilters}
    />
  )
}
