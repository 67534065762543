import {FeatureModel} from '../../models/FeatureModel'

export type FeatureCode =
  | 'EMSBOOKINGS'
  | 'EMSEVENTS'
  | 'EMSACTIVITY'
  | 'EMSPRODCAT'
  | 'EMSPRODUCT'
  | 'EMSBOOKINGS'
  | 'EMSBUNDLE'
  | 'EMSGUESTS'
  | 'EMSTICKETS'
  | 'EMSBADGECOLLECTION'
  | 'EMSRESERVATIONS'
  | 'EMSWORKSHOPS'
  | 'EMSNOTIFICATIONS'
  | 'EMSBADGE'
  | 'EVAAGENDA'
  | 'EVATOPICS'
  | 'EVASPEAKERS'
  | 'EVAPOLLS'
  | 'ACSVENUES'
  | 'ACSLOCATION'
  | 'ACSGATES'
  | 'ACSLOGS'
  | 'FNBOUTLETS'
  | 'FNBPRODUCTS'
  | 'FNBPRODCATS'
  | 'FNBUSERS'
  | 'FNBORDERS'
  | 'FNBLOGS'
  | 'FNBTABLERES'
  | 'FNBQRGEN'
  | 'FNBIMPORT'
  | 'FNBINVENTORY'
  | 'SVCCUST'
  | 'SVCCUSTCATS'
  | 'SVCVOUCHER'
  | 'SVCIMPORT'
  | 'SVCDASHBOARD'
  | 'SVCTEMPLATE'
  | 'SVCWORKFLOW'
  | 'CMSPOSTS'
  | 'CMSFILES'
  | 'CMSPAGES'
  | 'CMSFAQS'
  | 'CMSLIBRARY'
  | 'CMSPARTNER'
  | 'EMSPOKENS'
  | 'MERCHANDISEOUTLETS'
  | 'MERCHANDISEPRODCATS'
  | 'MERCHANDISEPRODUCTS'
  | 'MERCHANDISEORDERS'
  | 'MERCHANDISEUSERS'
  | 'MERCHANDISELOGS'
  | 'EMSTEAMTYPES'
  | 'EMSTEAMS'
  | 'EMSTEAMMEMBERS'
  | 'EMSDEVICES'
  | 'VOUCHERCASHIN'
  | 'VOUCHERCASHOUT'
  | 'SVCWORKFLOWBUILDER'
  | 'SVCWORKFLOWBUILDERLOGS'
  | 'EMSCHANNEL'

export class Feature {
  constructor(private feature: FeatureModel) {}

  public getCode(): FeatureCode {
    return this.feature.code as FeatureCode
  }

  public getName() {
    return this.feature.name
  }

  public canRead = (): boolean => {
    return this.feature.rw !== undefined
  }
  public canManage = (): boolean => {
    return Boolean(this.feature.rw)
  }
}
