import {useCallback, useMemo, useState} from 'react'
import {FormikContextType} from 'formik'
import {WizardControlProvider} from '../../../../../components/forms/Wizard/WizardControlProvider'
import {useWizardFormikHelpers} from '../../../../../components/forms/Wizard/useEventWizardHelpers'
import {WizardControls, WizardStep} from '../../../../../components/forms/Wizard/WizardControls'
import {WizardSteppers} from '../../../../../components/forms/Wizard/WizardSteppers'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {BookingWizardEventStep} from './steps/BookingWizardEventStep'
import {BookingWizardCreateBulkCustomerStep} from './steps/bulkBooking/BookingWizardCreateBulkCustomerStep'
import {BookingWizardBulkCustomerStep} from './steps/bulkBooking/BookingWizardBulkCustomerStep'
import {BookingWizardBulkProductVenueStep} from './steps/bulkBooking/BookingWizardBulkProductVenueStep'
import {BookingWizardBulkFinalizeStep} from './steps/bulkBooking/BookingWizardBulkFinalizeStep'
import {BulkBookingFormValues} from '../../../../../models/booking-wizard/BulkBookingWizard'

export interface BookingBulkWizardProps {
  formik: FormikContextType<BulkBookingFormValues>
  disabledFields?: Partial<Record<keyof BulkBookingFormValues, boolean>>
  hiddenFields?: Partial<Record<keyof BulkBookingFormValues, boolean>>
  step: number
  onStepChange: (step: number) => void
  event?: EventModel | null
  customer?: CustomerModel
  isEdit?: boolean
  onSubmit?: () => void
}

export const BookingBulkWizard = ({
  formik,
  hiddenFields,
  disabledFields,
  step: currentStep,
  onStepChange,
  event,
  customer,
  isEdit,
  onSubmit,
}: BookingBulkWizardProps) => {
  const [noCustomer, setNoCustomer] = useState<boolean>(true)
  const {getStepState} = useWizardFormikHelpers({
    formik,
    currentStep,
    noErrors: currentStep === 3,
  })

  useOnChange(event, () => {
    formik.setFieldValue('eventCode', event?.code)
  })

  const isStepperShown = useCallback(
    (stepperFields: (keyof BulkBookingFormValues)[]) => {
      if (!hiddenFields) {
        return true
      }
      const shouldShow = !stepperFields.every((field) => !!hiddenFields[field])
      return shouldShow
    },
    [hiddenFields]
  )

  const steps = useMemo((): WizardStep[] => {
    if (customer) {
      return [
        {
          title: 'Event',
          description: 'Search Event',
          state: getStepState(0, ['eventcode']),
          icon: {
            iconType: 'General',
            iconName: 'Search',
          },
          fields: ['eventCode'],
          hidden: !isStepperShown(['eventCode']),
        },
        {
          title: 'Event',
          description: 'Search Event',
          state: getStepState(1, ['eventcode']),
          icon: {
            iconType: 'General',
            iconName: 'Search',
          },
          fields: ['eventCode'],
          hidden: true,
        },
        {
          title: 'Product/Voucher',
          description: 'select product & voucher',
          state: getStepState(2, ['product', 'voucher']),
          icon: {
            iconType: 'Cooking',
            iconName: 'Cutting board',
          },
          fields: ['product', 'voucher'],
          hidden: !isStepperShown(['product', 'voucher']),
        },
        {
          title: 'Finalize',
          description: 'Fulfill booking products',
          state: getStepState(3, []),
          icon: {
            iconType: 'Communication',
            iconName: 'Flag',
          },
        },
      ]
    } else if (event)
      return [
        {
          title: 'Customer',
          description: 'Search customer',
          state: getStepState(0, ['customers']),
          icon: {
            iconType: 'Communication',
            iconName: 'Add-user',
          },
          fields: ['customers'],
          hidden: !isStepperShown(['customers']),
        },
        {
          title: 'New Customer',
          description: 'Create customer',
          state: getStepState(1, ['customers']),
          icon: {
            iconType: 'Communication',
            iconName: 'Add-user',
          },
          fields: ['customers'],
          hidden: Boolean(isEdit),
        },
        {
          title: 'Product/Voucher',
          description: 'select product & voucher',
          state: getStepState(2, ['product', 'voucher']),
          icon: {
            iconType: 'Cooking',
            iconName: 'Cutting board',
          },
          fields: ['product', 'voucher'],
          hidden: !isStepperShown(['product', 'voucher']),
        },
        {
          title: 'Finalize',
          description: 'Fulfill booking products',
          state: getStepState(3, ['customersSeats']),
          hidden: !isStepperShown(['customersSeats']),
          fields: ['customersSeats'],
          icon: {
            iconType: 'Communication',
            iconName: 'Flag',
          },
        },
      ]
    return []
  }, [customer, event, getStepState, isEdit, isStepperShown])

  const handleNoCustonmers = useCallback((is: boolean) => {
    if (is) {
      setNoCustomer(false)
    } else {
      setNoCustomer(true)
    }
  }, [])

  const handleCustomerStepChange = useCallback(
    (step: number) => {
      formik.setFieldValue('customer', '')
      onStepChange(step)
    },
    [formik, onStepChange]
  )

  const handleCreateCustomer = useCallback(() => {
    onStepChange(2)
  }, [onStepChange])

  const stepPage = useMemo(() => {
    switch (currentStep) {
      case 0:
        if (customer) {
          return <BookingWizardEventStep formik={formik} disabledFields={disabledFields} />
        } else {
          return (
            <BookingWizardBulkCustomerStep
              formik={formik}
              disabledFields={disabledFields}
              event={event}
              isEdit={isEdit}
              onNoCustonmers={handleNoCustonmers}
              onStepChange={handleCustomerStepChange}
            />
          )
        }
      case 1:
        return (
          <BookingWizardCreateBulkCustomerStep
            formik={formik}
            handleCreateCustomer={handleCreateCustomer}
          />
        )
      case 2:
        return <BookingWizardBulkProductVenueStep formik={formik} event={event} />
      case 3:
        return <BookingWizardBulkFinalizeStep formik={formik} />
    }
  }, [
    currentStep,
    customer,
    disabledFields,
    event,
    formik,
    handleCreateCustomer,
    handleCustomerStepChange,
    handleNoCustonmers,
    isEdit,
  ])

  const handeleStepChange = useCallback(
    (page: number, type) => {
      if (page === 1 && formik.values.customers) {
        if (type === 'increament') {
          onStepChange(page + 1)
        }

        if (type === 'decreament') {
          onStepChange(page - 1)
        }
      } else onStepChange(page)
    },
    [formik.values.customers, onStepChange]
  )

  return (
    <WizardControlProvider
      currentPage={currentStep}
      onPageChange={handeleStepChange}
      steps={steps}
      isHasPrevious={currentStep === 3 || currentStep === 0 ? false : true}
    >
      <div className='container flex-grow-1'>
        <div className='row'>
          <div className='col-12'>
            <WizardSteppers currentStep={currentStep} steps={steps} />
          </div>
          <div className='col-12'>{stepPage}</div>
          <div className='col-12'>
            <WizardControls
              formik={formik}
              steps={steps}
              submitLabel='Done'
              isHasNext={currentStep === 0 ? noCustomer : currentStep === 1 ? false : true}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </WizardControlProvider>
  )
}
