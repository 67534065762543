import {useCallback, useMemo} from 'react'
import {Button} from '../Button'
import {SeatMapCanvasInputProps} from './SeatMapCanvasInput'
import {SeatMapSelectionInput} from './SeatMapSelectionInput'
import {SeatMapValue} from './SeatMapValue'
import {TreeNodeItem} from '../TreeSelect/TreeSelect'
import {ResponsiveModal} from '../../modals/ResponsiveModal'
import {MetronicIconButton} from '../MetronicIconButton'
import {LoadingSpinner} from '../../utils/LoadingSpinner'
import {SeatMapBoxLegends, SeatMapBoxLegendsProps} from './SeatMapBoxLegends'
import {getSeatDaysColor} from './hooks/useSeatHelper'

export interface SeatMapSelectionModalInputProps extends SeatMapCanvasInputProps {
  open: boolean
  onHide: () => void
  count: number
  onSubmit: () => void
  locationItems?: TreeNodeItem[]
  onLocationChange?: (value: string) => void
  locationCode?: string
  disableSubmit?: boolean
  modalTitle?: string | ((remaining: number) => string)
  loading?: boolean
  disableSelection?: boolean
  legends?: JSX.Element
  isCircledDaysBox?: boolean
  isShowNoneFilledDays?: boolean
  multipleLocation?: boolean
}

export const SeatMapSelectionModalInput = ({
  className,
  open,
  onHide,
  count,
  value,
  onChange,
  onSubmit,
  disableSubmit,
  modalTitle,
  disableSelection,
  loading = false,
  legends,
  isCircledDaysBox,
  isShowNoneFilledDays,
  multipleLocation,
  ...props
}: SeatMapSelectionModalInputProps) => {
  
  const remaining = useMemo(() => {
    const selectCount = value?.getValueCount() || 0
    return count - selectCount
  }, [value, count])

  const handleOnChange = useCallback(
    (values: SeatMapValue) => {
      if (!disableSelection && !loading) {
        const remaining = count - values.getValueCount()
        if (remaining >= 0) {
          onChange && onChange(values)
        }
      }
    },
    [disableSelection, loading, count, onChange]
  )

  const header = useMemo(() => {
    const title = `Please select seats: ${remaining} remaining.`
    let subtitle: string = ''
    if (modalTitle) {
      if (typeof modalTitle === 'string') {
        subtitle = modalTitle
      } else {
        subtitle = modalTitle(remaining)
      }
    }
    return (
      <div className='w-100 d-flex justify-content-between'>
        <h2>{title}</h2>
        <h2>{subtitle}</h2>
      </div>
    )
  }, [modalTitle, remaining])

  const seatsDaysLegends = useMemo<SeatMapBoxLegendsProps[] | null>(() => {
    if (props.seatsDays) {
      return Object.keys(props.seatsDays).map((item) => {
        return {
          text: item,
          color: getSeatDaysColor(item),
        }
      })
    }

    return null
  }, [props.seatsDays])

  return (
    <ResponsiveModal noShowAnimation noPadding size='big' onClose={onHide} open={open}>
      <div className='h-100 p-5 d-flex flex-column'>
        <div className='d-flex'>
          <div className='px-5 pt-5 flex-grow-1'>{header}</div>
          <MetronicIconButton
            variant='text'
            size='sm'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onHide}
          />
        </div>
        <div className='flex-grow-1'>
          <SeatMapSelectionInput
            selected={value}
            className={className}
            onSelectedChange={handleOnChange}
            maxSelected={count}
            isCircledDaysBox={isCircledDaysBox}
            isShowNoneFilledDays={isShowNoneFilledDays}
            {...props}
          />
        </div>
        <div className='d-flex gap-10 my-5'>
          {legends ? <div>{legends}</div> : null}
          {props.seatsDays && seatsDaysLegends && (
            <SeatMapBoxLegends data={seatsDaysLegends} isCircledDaysBox={isCircledDaysBox} />
          )}
        </div>

        <div className='pt-2 d-flex flex-row-reverse'>
          <Button
            type='button'
            className='px-12'
            disabled={multipleLocation ? disableSubmit || loading : disableSubmit || loading || remaining !== 0}
            variant='primary'
            onClick={onSubmit}
          >
            <LoadingSpinner loading={loading}>OK</LoadingSpinner>
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  )
}
